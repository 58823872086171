import {
  Container,
  Typography,
  Avatar,
  IconButton,
  Box,
  Chip,
  CircularProgress,
} from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Package1 from "../assets/images/package1.png";
import { useState, useEffect } from "react";
import Services from "../services/Services";
import {
  AddCircleOutline,
  ArrowForwardIos,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Language from "../languages";

export default function PastRequestsDetailPage() {
  const navigate = useNavigate();
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const { t, i18n } = useTranslation();
  const { index } = useParams();
  const location = useLocation();
  const posts = location?.state?.postList;
  const locationID = location?.state?.locationID;
  const [loading, setLoading] = useState(false);

  const findLocationID = (orderID) => {
    const matchedRequest = posts.find((request) => request.orderID === orderID);
    return matchedRequest ? matchedRequest.locationID : "Lokasyon Bulunamadı";
  };

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent
          middle="logo"
          right="icon"
          left="back"
          goBack={() => navigate(-1)}
        />

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box sx={{ marginTop: 5 }}>
            <Typography
              variant="subtitle1"
              sx={{
                lineHeight: "16.34px",
                fontSize: 12,
                fontWeight: 700,
                marginBottom: 2,
              }}
            >
              {language === "tr"
                ? `${posts[0]?.orderID} Talebinin Gönderileri`
                : `Status of Request ${posts[0]?.orderID}`}
            </Typography>

            {posts?.length > 0 ? (
              <>
                {posts.map((item, index) => (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      height: 73,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      backgroundColor: "#ffffff",
                      elevation: 4,
                      border: "1px solid  #C1C6C9",
                      shadowColor: "#092420",
                      shadowOpacity: 0.13,
                      shadowOffset: {
                        width: 0,
                        height: 4,
                      },
                      shadowRadius: 10,
                      borderRadius: 8,
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src={Package1}
                        alt="easypoint logo"
                        width={25}
                        height={25}
                        style={{ marginLeft: 20, marginRight: 20 }}
                      />

                      <Box>
                        <Typography
                          sx={{
                            lineHeight: "16.94px",
                            fontSize: 14,
                            fontWeight: 700,
                            marginBottom: 1,
                          }}
                        >
                          {item.orderNumber}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#015876",
                            lineHeight: "14.52px",
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                        >
                          {item.status === "3"
                            ? Language(mall, language, "inthestore")
                            : item.status === "4"
                            ? Language(mall, language, "ontheway")
                            : item.status === "5"
                            ? Language(mall, language, "pickup")
                            : item.status === "1"
                            ? Language(mall, language, "completed")
                            : item.status === "2"
                            ? Language(mall, language, "canceled")
                            : Language(mall, language, "unknown")}
                        </Typography>
                      </Box>
                    </Box>

                    <IconButton
                      aria-label="goBack"
                      size="small"
                      color="pure"
                      sx={{ marginRight: 1 }}
                      onClick={() =>
                        navigate("/post-details", {
                          state: {
                            ...item,
                            locationID: findLocationID(item.orderID),
                          },
                        })
                      }
                    >
                      <ArrowForwardIos fontSize="inherit" />
                    </IconButton>
                  </Box>
                ))}
              </>
            ) : (
              <Typography
                sx={{
                  lineHeight: "16.34px",
                  fontSize: 12,
                  fontWeight: 600,
                  marginBottom: 5,
                  color: "gray",
                }}
              >
                Paket Bulunmamaktadır.
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Typography
        sx={{
          color: "black",
          fontSize: 8,
          textAlign: "center",
          marginBottom: 5,
        }}
      >
        version 0.2.5
      </Typography>
    </Container>
  );
}
