import {
  Container,
  Typography,
  TextField,
  Button,
  Checkbox,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Services from "../services/Services";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Language from "../languages";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function MainPage() {
  const navigate = useNavigate();
  const projectName = useSelector((state) => state.projectName);
  const _mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const uri = localStorage.getItem("mallUri");
  const storedMall = localStorage.getItem("mall");
  const [loginType, setLoginType] = useState("2");
  const [formData, setFormData] = useState({
    firm: "",
    name: "",
    lastname: "",
    phone: "",
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    deliveryType: "",
  });
  const [additionalNote, setAdditionalNote] = useState("");
  const [mall, setMall] = useState();
  const [code, setCode] = useState("");
  const [userCode, setUserCode] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [disabledPhone, setDisabledPhone] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [firstLoginComplete, setFirstLoginComplete] = useState(false);
  const [showRoomNo, setShowRoomNo] = useState(false);
  const [deliveryTypes, setDeliveryTypes] = useState([]);

  const [predefinedTypes, setPredefinedTypes] = useState([]);
  const [selectedAddressType, setSelectedAddressType] = useState("");
  const [selectedPredefined, setSelectedPredefined] = useState("");
  const [hotelRoom, setHotelRoom] = useState("");
  const [pointsTypes, setPointsTypes] = useState([]);
  const [selectedPointType, setSelectedPointType] = useState("");
  const [selectedSubValue, setSelectedSubValue] = useState("");
  const [townList, setTownList] = useState([]);
  const [selectedTown, setSelectedTown] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [lockBoxList, setLockBoxList] = useState([]);
  const [selectedLockBox, setSelectedLockBox] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [addressDescription, setAddressDescription] = useState("");

  const handleSuccess = () => {
    setSuccess(true);
    setLoading(false);
  };

  const handleFailed = () => {
    setFailed(true);
    setLoading(false);
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "deliveryType") {
      setSelectedAddressType("");
      setSelectedPredefined("");
      setSelectedPointType("");
      setSelectedSubValue("");
      setSelectedTown("");
      setSelectedLockBox("");
      setSelectedDistrict("");
      setFullAddress("");
      setAddressDescription("");
    }
  };

  const handlePointTypeChange = (event) => {
    setSelectedPointType(event.target.value);
    setSelectedSubValue("");
  };

  const handlePredefinedTypeChange = (event) => {
    setSelectedAddressType(event.target.value);
    setSelectedPredefined("");
    setSelectedTown("");
    setHotelRoom("");
    setSelectedDistrict("");
    setFullAddress("");
    setAddressDescription("");
  };

  const handleCreate = (token, name, lastname, phone, firm, additionalNote) => {
    // console.log("formData.deliveryType:", formData.deliveryType);
    // console.log("selectedAddressType:", selectedAddressType);
    // console.log("selectedPredefined:", selectedPredefined);
    // console.log("selectedTown:", selectedTown, JSON.parse(storedMall)[0].deliveryTypes.localDelivery[selectedTown]);
    // console.log("selectedDistrict:", selectedDistrict);
    // console.log("selectedSubValue:", selectedSubValue);
    // console.log("selectedLockBox:", selectedLockBox);
    // console.log("selectedPointType:", selectedPointType);

    let deliveryTypeInfo = {};

    if (selectedSubValue) {
      deliveryTypeInfo = {
        deliveryType: formData.deliveryType,
        type: selectedSubValue.type,
        id: selectedSubValue.id,
        text: selectedSubValue.title,
        additionalNote: addressDescription,
        price: selectedSubValue.price,
        currency: selectedSubValue.currency,
      };
    } else if (selectedPredefined) {
      deliveryTypeInfo = {
        deliveryType: formData.deliveryType,
        type: "predefined",
        text: selectedPredefined.title,
        additionalNote: "(Oda No: " + hotelRoom + ") " + addressDescription,
        price: selectedPredefined.price,
        currency: selectedPredefined.currency,
      };
    } else if (selectedTown) {
      deliveryTypeInfo = {
        deliveryType: formData.deliveryType,
        type: "custom",
        text: selectedTown + " " + selectedDistrict + " " + fullAddress,
        additionalNote: addressDescription,
        price:
          JSON.parse(storedMall)[0].deliveryTypes.localDelivery[selectedTown]
            .price,
        currency:
          JSON.parse(storedMall)[0].deliveryTypes.localDelivery[selectedTown]
            .currency,
      };
    } else if (selectedLockBox) {
      deliveryTypeInfo = {
        deliveryType: formData.deliveryType,
        locationCode: selectedLockBox.locationCode,
        text: selectedLockBox.title,
        additionalNote: addressDescription,
        price: selectedLockBox.price,
        currency: selectedLockBox.currency,
        id: selectedLockBox.id,
      };
    }

    Services.CreateOrder(
      token,
      name,
      lastname,
      phone,
      firm,
      `${name} ${lastname} ${additionalNote}`,
      deliveryTypeInfo
    )
      .then((res) => {
        // console.log("create order response:", res.data);
        if (res.data.status) {
          if (firstLogin) {
            localStorage.setItem("name", name);
            localStorage.setItem("lastname", lastname);
            localStorage.setItem("phone", phone);
          }
          handleSuccess();
        } else {
          handleFailed();
        }
      })
      .catch((err) => {
        // console.log(err);
        handleFailed();
      });
  };

  const userControl = () => {
    Services.GetCustomer(token, formData.phone)
      .then((res) => {
        // console.log("Get Customer Response: ", res.data);
        if (res.data.status) {
          localStorage.setItem("name", res.data.result.name);
          localStorage.setItem("lastname", res.data.result.lastname);
          localStorage.setItem("phone", res.data.result.phone);
          localStorage.setItem("customerID", res.data.result.customerID);
          setLoginType("3");
          setShowCode(false);
          setDisabledPhone(true);
          setFirstLoginComplete(true);
          setFormData({
            name: res.data.result.name,
            lastname: res.data.result.lastname,
            phone: res.data.result.phone,
          });
        } else {
          setLoginType("3");
          setShowCode(false);
          setDisabledPhone(true);
          setFirstLoginComplete(true);
        }
      })
      .catch((err) => {
        // console.log(err);
        setLoginType("3");
        setShowCode(false);
        setDisabledPhone(true);
        setFirstLoginComplete(true);
      });
  };

  const sendSMS = (phone) => {
    const _code = Math.floor(100000 + Math.random() * 900000).toString();
    setCode(_code);
    const message = `Telefon numaranızı doğrulamanız için ${_code} kodunu gerekli alana giriniz.`;
    Services.SendSMS(token, phone, message)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleContinue = () => {
    const { name, firm, lastname, phone, checkbox1, checkbox2 } = formData;
    //  console.log(
    //   "parametreler: ",
    //   name,
    //   lastname,
    //   phone,
    //   firm,
    //   checkbox1,
    //   checkbox2,
    //   additionalNote
    // );

    if (firstLogin && firstLoginComplete === false) {
      if (loginType === "2") {
        setShowCode(true);
        if (showCode) {
          if (userCode === code) {
            userControl();
          } else {
            window.alert(
              language === "tr"
                ? "Kodlar uyuşmuyor, tekrar deneyiniz."
                : "Codes don't match, please try again."
            );
          }
        } else {
          sendSMS(phone);
        }
      }
    } else {
      setLoading(true);
      handleCreate(token, name, lastname, phone, firm, additionalNote);
    }
  };

  function extractUniqueTypes(dataArray) {
    const types = new Set(); // Tekrar eden typeları önlemek için Set kullanıyoruz

    dataArray?.forEach((item) => {
      if (item.type) {
        types.add(item.type); // type varsa Set'e ekle
      }
    });

    return Array.from(types); // Set'i array'e dönüştürüyoruz
  }

  const mergeArrays = (data) => {
    return Object.values(data).flat();
  };

  const extractTowns = (obj) => {
    if (obj) {
      return Object.keys(obj).map((town) => town);
    }
  };

  function checkDataAvailability(data) {
    const result = {};

    let deliveryTypesArr = [];
    let predefinedTypesArr = extractUniqueTypes(data?.predefined);
    let pointsTypesArr = extractUniqueTypes(data?.points);
    let townListArr = extractTowns(data?.localDelivery);
    let lockBoxListArr = data.postbox;
    // Genel kontrol fonksiyonu: değer var mı ve null, false veya boş değil mi?
    const isValid = (value) =>
      value !== null &&
      value !== false &&
      value !== undefined &&
      (Array.isArray(value)
        ? value?.length > 0
        : Object.keys(value || {})?.length > 0);

    // Points kontrolü
    if (isValid(data.points)) {
      result.points = "Points mevcut ve geçerli.";
      deliveryTypesArr.push({ type: "points", title: "Easy Point" });
    }

    // Predefined kontrolü
    if (isValid(data.predefined) || isValid(data.localDelivery)) {
      result.predefined = "Predefined mevcut ve geçerli.";
      deliveryTypesArr.push({
        type: "address",
        title: Language(_mall, language, "deliverToAddress"),
      });
    }

    // Postbox kontrolü
    if (isValid(data.postbox)) {
      result.postbox = "Postbox mevcut ve geçerli.";
      deliveryTypesArr.push({
        type: "postbox",
        title: Language(_mall, language, "deliverToLockBox"),
      });
    }

    // LocalDelivery kontrolü
    if (isValid(data.localDelivery)) {
      result.localDelivery = "LocalDelivery mevcut ve geçerli.";
    }

    if (townListArr?.length === 1) {
      setSelectedTown(townListArr[0]);
    }

    setLockBoxList(lockBoxListArr);
    setTownList(townListArr);
    setPointsTypes(pointsTypesArr);
    setPredefinedTypes(predefinedTypesArr);
    setDeliveryTypes(deliveryTypesArr);
  }

  const handleChangeTown = (event) => {
    const parsedMall = JSON.parse(storedMall);
    const data = parsedMall[0].deliveryTypes;
    setSelectedTown(event.target.value);
    setDistrictList(data.localDelivery[event.target.value].neigbours);
    setSelectedDistrict("");
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedName = localStorage.getItem("name");
    const storedLastname = localStorage.getItem("lastname");
    const storedPhone = localStorage.getItem("phone");
    setToken(storedToken);
    // // console.log("stored Mall: ", storedMall);
    if (storedMall) {
      const parsedMall = JSON.parse(storedMall);

      checkDataAvailability(parsedMall[0]?.deliveryTypes);
      // // console.log("parsedMall: ", parsedMall);
      if (parsedMall[0]?.uri === "istinyepark-izmir") {
        setShowRoomNo(true);
      }
      setMall(parsedMall);
    } else {
      // burada avm seçilmesi için bir state tetiklenmeli
    }
    if (storedName) {
      setFormData((prevData) => ({
        ...prevData,
        name: storedName,
        lastname: storedLastname,
        phone: storedPhone,
      }));
      setDisabledPhone(true);

      setFirstLogin(false);
      setLoginType("1");
    } else {
      setFormData((prevData) => ({
        ...prevData,
        name: "",
        lastname: "",
        phone: "",
      }));
      setDisabledPhone(false);

      setFirstLogin(true);
      setLoginType("2");
    }
  }, [storedMall, language]);

  const isButtonDisabled = () => {
    if (!formData.deliveryType) return true;

    if (formData.deliveryType === "points") {
      return !selectedPointType || !selectedSubValue;
    }

    if (formData.deliveryType === "address") {
      if (!selectedAddressType) return true;

      if (selectedAddressType === "address") {
        return !selectedTown || !selectedDistrict || !fullAddress;
      }
      if (selectedAddressType === "predefined") {
        return (
          !selectedPredefined ||
          (selectedPredefined.type === "hotel" && !hotelRoom)
        );
      }
    }

    if (formData.deliveryType === "postbox") {
      return !selectedLockBox;
    }

    return false;
  };

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent
          middle="logo"
          goBack={() => navigate(`/${uri}`)}
          left="back"
          right="icon"
        />

        <Box sx={{ marginTop: 10 }}>
          <Typography
            variant="h5"
            component="h4"
            textAlign="center"
            sx={{
              lineHeight: "26.63px",
              fontSize: 22,
              fontWeight: 700,
              marginBottom: 2,
            }}
          >
            {projectName}
          </Typography>
          <Typography
            variant="subtitle2"
            component="h4"
            textAlign="center"
            marginX={8}
            sx={{
              color: "#5E596D",
              lineHeight: "16.94px",
              fontSize: 14,
              fontWeight: 400,
              marginBottom: 5,
            }}
          >
            {loginType === "1" || loginType === "3"
              ? Language(_mall, language, "selectStoreTitle")
              : Language(_mall, language, "selectPhone")}
          </Typography>
          {(loginType === "1" || loginType === "3") && (
            <>
              <FormControl fullWidth>
                <InputLabel
                  style={{ color: "#C1C6C9", fontSize: 15 }}
                  id="demo-simple-select-label"
                >
                  {/* {t("firm")}* */}
                  {Language(_mall, language, "firm")}*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={Language(_mall, language, "firm")}
                  name="firm"
                  value={formData.firm}
                  onChange={handleInputChange}
                  sx={{
                    marginBottom: 2,
                    backgroundColor: "#ffffff",
                    borderColor: "#C1C6C9",
                  }}
                >
                  {mall &&
                    mall[0].stores.map((store) => (
                      <MenuItem key={store.id} value={store.id}>
                        {store.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                id="outlined-basic-1"
                label={`${Language(_mall, language, "name")}*`}
                variant="outlined"
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { color: "#C1C6C9", fontSize: 15 },
                }}
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#ffffff",
                  borderColor: "#C1C6C9",
                }}
              />
              <TextField
                id="outlined-basic-2"
                label={`${Language(_mall, language, "lastname")}*`}
                variant="outlined"
                fullWidth
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { color: "#C1C6C9", fontSize: 15 },
                }}
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#ffffff",
                  borderColor: "#C1C6C9",
                }}
              />
            </>
          )}
          <TextField
            id="outlined-basic-3"
            label={`${Language(_mall, language, "phone")}*`}
            variant="outlined"
            placeholder="5XXXXXXXXX"
            fullWidth
            name="phone"
            disabled={disabledPhone}
            value={formData.phone}
            onChange={handleInputChange}
            InputLabelProps={{
              style: { color: "#C1C6C9", fontSize: 15 },
            }}
            sx={{
              marginBottom: showRoomNo ? 2 : 5,
              backgroundColor: "#ffffff",
              borderColor: "#C1C6C9",
            }}
          />
          {/* YENİ EKLENEN KISIM */}
          <FormControl fullWidth>
            <InputLabel
              style={{ color: "#C1C6C9", fontSize: 15 }}
              id="demo-simple-select-label"
            >
              {Language(_mall, language, "deliveryType")}*
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={Language(_mall, language, "deliveryType")}
              name="deliveryType"
              value={formData.deliveryType}
              onChange={handleInputChange}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffffff",
                borderColor: "#C1C6C9",
              }}
            >
              {mall &&
                deliveryTypes.map((type) => (
                  <MenuItem key={type.type} value={type.type}>
                    {type.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {formData.deliveryType === "points" && (
            <>
              <FormControl fullWidth>
                <InputLabel
                  style={{ color: "#C1C6C9", fontSize: 15 }}
                  id="demo-simple-select-label"
                >
                  {Language(_mall, language, "pointType")}*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={Language(_mall, language, "pointType")}
                  name="selectedPointType"
                  value={selectedPointType}
                  onChange={handlePointTypeChange}
                  sx={{
                    marginBottom: 2,
                    backgroundColor: "#ffffff",
                    borderColor: "#C1C6C9",
                  }}
                >
                  {mall &&
                    pointsTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type === "easypoint"
                          ? "Easy Point"
                          : Language(_mall, language, "location")}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              {/* İkinci Select Bileşeni */}
              {selectedPointType && (
                <FormControl fullWidth>
                  <InputLabel
                    style={{ color: "#C1C6C9", fontSize: 15 }}
                    id="second-select-label"
                  >
                    {selectedPointType === "easypoint"
                      ? "Easy Point"
                      : Language(_mall, language, "location")}
                    *
                  </InputLabel>
                  <Select
                    labelId="second-select-label"
                    id="second-select"
                    label={
                      selectedPointType === "easypoint"
                        ? "Easy Point"
                        : Language(_mall, language, "location")
                    }
                    value={
                      selectedSubValue ? JSON.stringify(selectedSubValue) : ""
                    }
                    onChange={(e) =>
                      setSelectedSubValue(JSON.parse(e.target.value))
                    }
                    sx={{
                      marginBottom: 2,
                      backgroundColor: "#ffffff",
                      borderColor: "#C1C6C9",
                    }}
                  >
                    {JSON.parse(storedMall)[0]
                      .deliveryTypes.points.filter(
                        (point) => point.type === selectedPointType
                      )
                      .map((point) => (
                        <MenuItem
                          key={point.type}
                          value={JSON.stringify(point)}
                        >
                          {point.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {formData.deliveryType === "address" && (
            <>
              <FormControl fullWidth>
                <InputLabel
                  style={{ color: "#C1C6C9", fontSize: 15 }}
                  id="demo-simple-select-label"
                >
                  {Language(_mall, language, "addressType")}*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={Language(_mall, language, "addressType")}
                  name="selectedAddressType"
                  value={selectedAddressType}
                  onChange={handlePredefinedTypeChange}
                  sx={{
                    marginBottom: 2,
                    backgroundColor: "#ffffff",
                    borderColor: "#C1C6C9",
                  }}
                >
                  {mall && predefinedTypes?.length > 0 && (
                    <MenuItem value={"predefined"}>
                      {Language(_mall, language, "enterPredefinedAddress")}
                    </MenuItem>
                  )}
                  {mall && (
                    <MenuItem value={"address"}>
                      {Language(_mall, language, "enterAddress")}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              {selectedAddressType === "address" && (
                <>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                      gap: "15px",
                    }}
                  >
                    <TextField
                      id="outlined-basic-4"
                      label={Language(_mall, language, "city")}
                      variant="outlined"
                      fullWidth
                      name="city"
                      value={JSON.parse(storedMall)[0].city}
                      disabled
                      InputLabelProps={{
                        style: { color: "#C1C6C9", fontSize: 15 },
                      }}
                      sx={{
                        marginBottom: 2,
                        backgroundColor: "#ffffff",
                        borderColor: "#C1C6C9",
                      }}
                    />

                    <FormControl fullWidth>
                      <InputLabel
                        style={{ color: "#C1C6C9", fontSize: 15 }}
                        id="demo-simple-select-label"
                      >
                        {Language(_mall, language, "district")}*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={Language(_mall, language, "district")}
                        name="selectedAddressType"
                        value={selectedTown}
                        disabled={townList?.length === 1}
                        onChange={handleChangeTown}
                        sx={{
                          marginBottom: 2,
                          backgroundColor: "#ffffff",
                          borderColor: "#C1C6C9",
                        }}
                      >
                        {mall &&
                          townList.map((town) => (
                            <MenuItem key={town} value={town}>
                              {town}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <FormControl fullWidth>
                    <InputLabel
                      style={{ color: "#C1C6C9", fontSize: 15 }}
                      id="demo-simple-select-label"
                    >
                      {Language(_mall, language, "town")}*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={Language(_mall, language, "town")}
                      name="selectedTown"
                      value={selectedDistrict}
                      disabled={!selectedTown}
                      onChange={(e) => setSelectedDistrict(e.target.value)}
                      sx={{
                        marginBottom: 2,
                        backgroundColor: "#ffffff",
                        borderColor: "#C1C6C9",
                      }}
                    >
                      {mall &&
                        districtList?.map((district) => (
                          <MenuItem key={district} value={district}>
                            {district}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </>
          )}
          {selectedAddressType === "predefined" && (
            <>
              <FormControl fullWidth>
                <InputLabel
                  style={{ color: "#C1C6C9", fontSize: 15 }}
                  id="demo-simple-select-label"
                >
                  {Language(_mall, language, "enterPredefinedAddress")}*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={Language(_mall, language, "enterPredefinedAddress")}
                  name="selectedPredefined"
                  value={selectedPredefined}
                  onChange={(e) => setSelectedPredefined(e.target.value)}
                  sx={{
                    marginBottom: 2,
                    backgroundColor: "#ffffff",
                    borderColor: "#C1C6C9",
                  }}
                >
                  {mall &&
                    mall[0].deliveryTypes.predefined.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              {selectedPredefined.type === "hotel" && (
                <TextField
                  id="outlined-basic-4"
                  label={`${Language(_mall, language, "roomNo")}*`}
                  variant="outlined"
                  fullWidth
                  name="hotelRoom"
                  value={hotelRoom}
                  onChange={(e) => setHotelRoom(e.target.value)}
                  InputLabelProps={{
                    style: { color: "#C1C6C9", fontSize: 15 },
                  }}
                  sx={{
                    marginBottom: 5,
                    backgroundColor: "#ffffff",
                    borderColor: "#C1C6C9",
                  }}
                />
              )}
            </>
          )}
          {formData.deliveryType === "postbox" && (
            <>
              <FormControl fullWidth>
                <InputLabel
                  style={{ color: "#C1C6C9", fontSize: 15 }}
                  id="demo-simple-select-label"
                >
                  {Language(_mall, language, "lockboxLocation")}*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={Language(_mall, language, "lockboxLocation")}
                  name="selectedLockBox"
                  value={selectedLockBox}
                  onChange={(e) => setSelectedLockBox(e.target.value)}
                  sx={{
                    marginBottom: 2,
                    backgroundColor: "#ffffff",
                    borderColor: "#C1C6C9",
                  }}
                >
                  {mall &&
                    lockBoxList.map((lockBox) => (
                      <MenuItem key={lockBox.locationCode} value={lockBox}>
                        {lockBox.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          )}
          {formData.deliveryType && (
            <>
              {selectedAddressType === "address" && (
                <TextField
                  id="outlined-multiline-1"
                  label={Language(_mall, language, "enterFullAddress")}
                  multiline
                  fullWidth
                  rows={4}
                  value={fullAddress}
                  onChange={(e) => setFullAddress(e.target.value)}
                  InputProps={{
                    style: {
                      height: 100,
                    },
                  }}
                  sx={{
                    marginBottom: 2,
                    backgroundColor: "#ffffff",
                    borderColor: "#C1C6C9",
                  }}
                />
              )}

              <TextField
                id="outlined-multiline-2"
                label={
                  selectedAddressType === "address"
                    ? Language(_mall, language, "enterAddressNote")
                    : Language(_mall, language, "enterNote")
                }
                multiline
                fullWidth
                value={addressDescription}
                onChange={(e) => setAddressDescription(e.target.value)}
                rows={4}
                InputProps={{
                  style: {
                    height: 100,
                  },
                }}
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#ffffff",
                  borderColor: "#C1C6C9",
                }}
              />
            </>
          )}
          {showCode && (
            <TextField
              id="outlined-basic-3"
              label={`${Language(_mall, language, "code")}*`}
              variant="outlined"
              fullWidth
              name="userCode"
              value={userCode}
              onChange={(e) => setUserCode(e.target.value)}
              InputLabelProps={{
                style: { color: "#C1C6C9", fontSize: 15 },
              }}
              sx={{
                backgroundColor: "#ffffff",
                borderColor: "#C1C6C9",
                marginBottom: "20px",
              }}
            />
          )}
          {(loginType === "1" || loginType === "3") && (
            <FormGroup fullWidth>
              {_mall === "galataport" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...label}
                      checked={formData.checkbox3}
                      onChange={handleInputChange}
                      name="checkbox3"
                      defaultChecked
                      color="newRed"
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#5E596D",
                        lineHeight: "19.5px",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {/* {t("taxFree")} */}
                      {Language(_mall, language, "taxFree")}
                    </Typography>
                  }
                  sx={{ marginBottom: 5 }}
                />
              )}
            </FormGroup>
          )}
          {JSON.parse(storedMall)[0].calculationUnit === "free" && (
            <p style={{ color: "#DF524C" }}>
              {Language(_mall, language, "serviceIsFree")}
            </p>
          )}
          {JSON.parse(storedMall)[0].calculationUnit === "per-package" && (
            <>
              {selectedSubValue && (
                <p style={{ color: "#DF524C" }}>
                  {Language(
                    _mall,
                    language,
                    "priceWillBeCalculation",
                    selectedSubValue.price + " " + selectedSubValue.currency
                  )}
                </p>
              )}
              {selectedPredefined && (
                <p style={{ color: "#DF524C" }}>
                  {Language(
                    _mall,
                    language,
                    "priceWillBeCalculation",
                    selectedPredefined.price + " " + selectedPredefined.currency
                  )}
                </p>
              )}
              {selectedTown && (
                <p style={{ color: "#DF524C" }}>
                  {Language(
                    _mall,
                    language,
                    "priceWillBeCalculation",
                    JSON.parse(storedMall)[0].deliveryTypes.localDelivery[
                      selectedTown
                    ].price +
                      " " +
                      JSON.parse(storedMall)[0].deliveryTypes.localDelivery[
                        selectedTown
                      ].currency
                  )}
                </p>
              )}
              {selectedLockBox && (
                <p style={{ color: "#DF524C" }}>
                  {Language(
                    _mall,
                    language,
                    "priceWillBeCalculation",
                    selectedLockBox.price + " " + selectedLockBox.currency
                  )}
                </p>
              )}
            </>
          )}
          {JSON.parse(storedMall)[0].calculationUnit === "service" && (
            <>
              {selectedSubValue && (
                <p style={{ color: "#DF524C" }}>
                  {Language(
                    _mall,
                    language,
                    "priceWillBe",
                    selectedSubValue.price + " " + selectedSubValue.currency
                  )}
                </p>
              )}
              {selectedPredefined && (
                <p style={{ color: "#DF524C" }}>
                  {Language(
                    _mall,
                    language,
                    "priceWillBe",
                    selectedPredefined.price + " " + selectedPredefined.currency
                  )}
                </p>
              )}
              {selectedTown && (
                <p style={{ color: "#DF524C" }}>
                  {Language(
                    _mall,
                    language,
                    "priceWillBe",
                    JSON.parse(storedMall)[0].deliveryTypes.localDelivery[
                      selectedTown
                    ].price +
                      " " +
                      JSON.parse(storedMall)[0].deliveryTypes.localDelivery[
                        selectedTown
                      ].currency
                  )}
                </p>
              )}
              {selectedLockBox && (
                <p style={{ color: "#DF524C" }}>
                  {Language(
                    _mall,
                    language,
                    "priceWillBe",
                    selectedLockBox.price + " " + selectedLockBox.currency
                  )}
                </p>
              )}
            </>
          )}
          {/* Buraya eklenecek */}
          {selectedAddressType === "address" &&
            formData?.deliveryType === "address" && (
              <Typography
                component="ul"
                sx={{ paddingLeft: 2, marginBottom: "15px", color: "#5E596D" }}
              >
                <li>{Language(_mall, language, "timeInformationBefore")}</li>
                <li>{Language(_mall, language, "timeInformationAfter")}</li>
              </Typography>
            )}
          <Button
            variant="contained"
            color="newRed"
            fullWidth
            sx={{ paddingY: 1.2 }}
            onClick={handleContinue}
            disabled={
              (loginType !== "2" &&
                (loading ||
                  formData.name === "" ||
                  formData.lastname === "" ||
                  formData.firm === "")) ||
              isButtonDisabled()
            }
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              // `${t("continue")}`
              Language(_mall, language, "continue")
            )}
          </Button>
          {showCode && (
            <Button
              variant="text"
              color="newRed"
              fullWidth
              sx={{ paddingY: 1.2, marginTop: "20px" }}
              onClick={() => sendSMS(formData.phone)}
            >
              {Language(_mall, language, "codeAgain")}
            </Button>
          )}
        </Box>
      </Box>

      <Dialog open={success} onClose={() => setSuccess(false)}>
        <DialogTitle color={"green"}>
          {Language(_mall, language, "success")}
        </DialogTitle>
        <DialogContent>
          <Typography>{Language(_mall, language, "successMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccess(false);
              navigate(`/${uri}`);
            }}
            color="primary"
          >
            {/* {t("okay")} */}
            {Language(_mall, language, "okay")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={failed} onClose={() => setFailed(false)}>
        <DialogTitle color={"red"}>
          {Language(_mall, language, "error")}
        </DialogTitle>
        <DialogContent>
          <Typography>{Language(_mall, language, "errorMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFailed(false)} color="primary">
            {Language(_mall, language, "okay")}
          </Button>
        </DialogActions>
      </Dialog>

      <Typography
        sx={{
          color: "black",
          fontSize: 8,
          bottom: 5,
          textAlign: "center",
        }}
      >
        {Language(_mall, language, "version")} 0.2.5
      </Typography>
    </Container>
  );
}
