import { Container, Typography, Button, Box } from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate } from "react-router-dom";
import Success from "../assets/images/success-icon.png";

export default function PostSuccessPage() {
  const navigate = useNavigate();

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent middle="logo" />

        <Box
          sx={{
            marginTop: 15,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Success} alt="easyflower" width={270} height={140} />
          <Typography
            sx={{
              lineHeight: "21.78px",
              fontSize: 18,
              fontWeight: 700,
              marginTop: 5,
            }}
          >
            Hands Free Paketinizi Kaydettik
          </Typography>
          <Typography
            textAlign="center"
            sx={{
              lineHeight: "20.3px",
              fontSize: 14,
              fontWeight: 400,
              marginTop: 1,
            }}
          >
            Paketiniz Easy Point noktasına ulaştığında size bilgilendirme mesajı
            ileteceğiz.{" "}
          </Typography>
          <Button
            variant="contained"
            color="newRed"
            fullWidth
            sx={{ paddingY: 1.2, marginTop: 8 }}
            onClick={() => navigate("/")}
          >
            Hands Free Paketleri Gör
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
