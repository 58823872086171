import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { UseSelector } from "react-redux";

const resources = {
  tr: {
    translation: {
      // header
      createPost: "GÖNDERİ OLUŞTUR",
      // select mall
      mall: "AVM",
      selectMall: "AVM Seçiniz",
      continue: "DEVAM ET",
      // post listing
      welcome: "Handsfree'ye hoşgeldiniz! Lütfen giriş yapın.",
      login: "Giriş Yap",
      createHandsfree: "{projectName} Talebi Oluştur",
      handsfreeRequests: "{projectName} Talepleri",
      emptyHandsfreeRequests: "{projectName} talebiniz bulunmamaktadır.",
      awaitingStore: "Mağazadan onay bekleniyor",
      awaitingYou: "Sizden onay bekleniyor",
      readyPackages: "Teslime Hazır Paketler",
      pickup: "Teslim alınabilir",
      emptyReadyPackages: "Teslime hazır olan paketiniz bulunmamaktadır.",
      onthewayPackges: "Yoldaki Paketler",
      ontheway: "Yolda",
      emptyOnthewayPackages: "Yolda olan paketiniz bulunmamaktadır.",
      storePackages: "Mağazadaki Paketler",
      inthestore: "Mağazada",
      emptyStorePackages: "Mağazada paketiniz bulunmamaktadır.",
      pastRequests: "Geçmiş Talepler",
      completed: "Tamamlandı",
      canceled: "İptal edildi",
      emptyPastRequests: "Geçmiş talebiniz bulunmamaktadır.",
      // last update
      lastUpdate: "Son Güncelleme",
      refresh: "Yenile",
      version: "Versiyon",
      // create request
      selectStoreTitle: "Lütfen hizmet almak istediğiniz mağazayı seçiniz",
      firm: "Mağaza",
      name: "Ad",
      lastname: "Soyad",
      phone: "Telefon",
      roomNo: "Oda No",
      code: "Kod",
      codeAgain: "Tekrar Kod Gönder",
      conditions1: "{projectName} hizmetinden faydalanmak için",
      conditions2: "Kullanım Koşulları",
      conditions3: "ve ücretlendirmeleri kabul ediyorum.",
      taxFree: "Aldığım ürünlerde tax refund özelliğini kullanmak istiyorum.",
      // confirm packages
      pendingPackages: "Onay Bekleyen Paketler",
      timeLimit:
        "İlgili Handsfree gönderilerini X saat içinde alacağımı kabul ediyorum.",
      create: "OLUŞTUR",
      beingCreated: "Oluşturuluyor...",
      cancel: "İPTAL",
      canceling: "İptal Ediliyor...",
      confirmSuccessMessage: "Paketleri onayladınız, iyi alışverişler dileriz.",
      rejectSuccessMessage:
        "Paketler iptal edilmiştir, iyi alışverişler dileriz.",

      // detail
      request: "Talep",
      package: "Paket",
      deliveryInfo: "Teslimat Bilgisi",
      deliveryStatus: "Teslimat Durumu",
      unknown: "Bilinmiyor",
      approved: "Onaylandı",
      rejected: "Talep reddedildi",
      deliveryCode: "Teslimat Şifresi",
      creationDate: "Oluşturulma Tarihi",
      store: "Mağaza",
      orderNo: "Sipariş No",
      deadlineForDelivery: "Son Teslim Tarihi",
      // modals
      success: "Başarılı",
      successMessage: "Talep başarıyla oluşturulmuştur.",
      error: "Hata",
      errorMessage: "İşlem gerçekleştirilemedi.",
      okay: "Tamam",
      close: "Kapat",
    },
  },

  en: {
    translation: {
      // header
      createPost: "CREATE POST",
      // select mall
      mall: "Shopping Center",
      selectMall: "Select Shopping Center",
      continue: "CONTINUE",
      // post listing
      welcome: "Welcome to Handsfree! Please log in.",
      login: "Login",
      createHandsfree: "Request {projectName}",
      handsfreeRequests: "{projectName} Requests",
      emptyHandsfreeRequests: "You do not have a {projectName} request.",
      awaitingStore: "Awaiting approval from the store",
      awaitingYou: "Awaiting approval from you",
      readyPackages: "Packages ready for pick-up",
      pickup: "Ready for pick-up",
      emptyReadyPackages: "You do not have any packages ready for pick-up.",
      onthewayPackges: "Packages on delivery",
      ontheway: "On the Way",
      emptyOnthewayPackages: "You do not have any packages on delivery.",
      storePackages: "Packages In the Store",
      inthestore: "In the Store",
      emptyStorePackages: "You do not have any packages in the store.",
      pastRequests: "Past Requests",
      completed: "Completed",
      canceled: "Canceled",
      emptyPastRequests: "You do not have any past requests.",
      // last update
      lastUpdate: "Latest Update",
      refresh: "Refresh",
      version: "Version",
      // create request
      selectStoreTitle:
        "Please select the store you would like to receive service from",
      firm: "Store",
      name: "Name",
      lastname: "Lastname",
      phone: "Phone",
      roomNo: "Room No",
      code: "Code",
      codeAgain: "Resend Code",
      conditions1: "I agree to the",
      conditions2: "conditions",
      conditions3: "and charges for the {projectName} service.",
      taxFree: "I would like to use tax refund on my purchases.",
      // confirm packages
      pendingPackages: "Packages Pending Approval",
      timeLimit:
        "I agree to receive relevant Handsfree shipments within X hours.",
      create: "CREATE",
      beingCreated: "Being Created...",
      cancel: "CANCEL",
      canceling: "Canceling...",
      confirmSuccessMessage:
        "You have confirmed the packages, have a good shopping experience.",
      rejectSuccessMessage:
        "Packages have been canceled, we wish you a good shopping experience.",
      // detail
      request: "Request",
      package: "Package",
      deliveryInfo: "Delivery Info",
      deliveryStatus: "Delivery Status",
      unknown: "Unknown",
      approved: "Approved",
      rejected: "Rejected declined",
      deliveryCode: "Delivery Code",
      creationDate: "Creation Date",
      store: "Store",
      orderNo: "Order No",
      deadlineForDelivery: "Deadline for Delivery",
      // modals
      success: "Success",
      successMessage: "The request was successfully created.",
      error: "Error",
      errorMessage: "Transaction could not be processed.",
      okay: "Okay",
      close: "Close",
    },
  },
};

i18n.use(initReactI18next).init({
  lng: "tr",
  resources,
});

export default i18n;
