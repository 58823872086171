import React from 'react';
import './CreditCard.css';

// Kart logolarını import et
import visaLogo from '../assets/visa.png';
import mastercardLogo from '../assets/mastercard.png';
import amexLogo from '../assets/amex.png';
import discoverLogo from '../assets/discover.png';
import jcbLogo from '../assets/jcb.png';
import { useSelector } from 'react-redux';
import Language from '../languages';

const CreditCard = ({ cardNumber, cardHolder, expiryDate }) => {
    const _mall = useSelector((state) => state.mall);
    const language = useSelector((state) => state.language);

    function getCardType() {
        const cardNumberStr = cardNumber.toString().replaceAll(" ", "").trim();
        const firstDigit = cardNumberStr[0];
        const firstTwoDigits = cardNumberStr.slice(0, 2);
        const firstFourDigits = cardNumberStr.slice(0, 4);
        const length = cardNumberStr.length;

        if (firstDigit === '4' && (length === 13 || length === 16)) {
            return visaLogo;
        } else if (firstTwoDigits >= '51' && firstTwoDigits <= '55' && length === 16) {
            return mastercardLogo;
        } else if ((firstTwoDigits === '34' || firstTwoDigits === '37') && length === 15) {
            return amexLogo;
        } else if ((firstFourDigits === '6011' || (firstFourDigits.slice(0, 3) >= '644' && firstFourDigits.slice(0, 3) <= '649') || firstTwoDigits === '65') && length === 16) {
            return discoverLogo;
        } else if (firstFourDigits >= '3528' && firstFourDigits <= '3589' && length === 16) {
            return jcbLogo;
        } else {
            return null; // Eğer kart bilinmiyorsa null döner
        }
    }

    const cardTypeLogo = getCardType();

    return (
        <div className="credit-card">
            <div className="card-logo">
                {cardTypeLogo && <img src={cardTypeLogo} alt="Card Logo" style={{ height: '30px' }} />}
            </div>
            <div className="card-number">
                <span style={{fontSize:'14px',color:'#C7C7C7', letterSpacing:0}}>{Language(_mall, language, "cardNumber")}</span>
                {cardNumber.replace(/(\d{4})(?=\d)/g, '$1 ')}
            </div>
            <div className="card-details">
                <div className="card-holder">
                    <span>{Language(_mall, language, "cardOwner")}</span>
                    <h3 style={{ fontSize: '16px', color: '#fff' }}>{cardHolder}</h3>
                </div>
                <div className="expiry-date">
                    <span>{Language(_mall, language, "expiryDate")}</span>
                    <h3 style={{ fontSize: '16px', color: '#fff' }}>{expiryDate}</h3>
                </div>
            </div>
        </div>
    );
};

export default CreditCard;
