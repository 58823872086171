const SET_PROJECTNAME = "SET_PROJECTNAME";
const SET_MALL = "SET_MALL";
const SET_LANGUAGE = "SET_LANGUAGE";

export const setProjectName = (projectName) => ({
  type: SET_PROJECTNAME,
  payload: projectName,
});

export const setMall = (mall) => ({
  type: SET_MALL,
  payload: mall,
});

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  payload: language,
});

const initialState = {
  projectName: "Hands Free",
  language: "tr",
  mall: "default",
};

const appReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PROJECTNAME:
      return { ...state, projectName: payload };
    case SET_MALL:
      return { ...state, mall: payload };
    case SET_LANGUAGE:
      return { ...state, language: payload };
    default:
      return state;
  }
};

export default appReducer;
