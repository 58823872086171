import * as React from "react";
import { useEffect } from "react";
import Router from "./routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import axios from "axios";

export default function App() {
  const useAxiosInterceptor = () => {
    useEffect(() => {
      const responseInterceptor = axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.message === "Network Error") {
            console.error("Network Error:", error);
          }
          return Promise.reject(error);
        }
      );

      return () => {
        axios.interceptors.response.eject(responseInterceptor);
      };
    }, []);
  };

  useAxiosInterceptor();

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Provider>
  );
}
