import React, { useState } from "react";
import { ArrowBackIosNew } from "@mui/icons-material";
import {
  Typography,
  Avatar,
  IconButton,
  Grid,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import Logo from "../assets/images/easypoint-logo-3.png";
import { useTranslation } from "react-i18next";
import UnitedKingdom from "../assets/images/united-kingdom.png";
import Turkiye from "../assets/images/turkey.png";
import { UseDispatch, useDispatch } from "react-redux";
import { setLanguage } from "../redux/appReducer";
import Services from "../services/Services";
import { useSelector } from "react-redux";
import NetworkStatusBar from "./networkStatusBar/NetworkStatusBar";

export default function HeaderComponent({
  left,
  middle,
  right,
  goBack,
  isLogin,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const storedName = localStorage.getItem("name");
  const storedLastname = localStorage.getItem("lastname");

  const initials =
    storedName &&
    storedName.trim() !== "" &&
    storedLastname &&
    storedLastname.trim() !== ""
      ? storedName.charAt(0).toUpperCase() +
        storedLastname.charAt(0).toUpperCase()
      : "HF";

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
    const token = localStorage.getItem("token");
    const customerID = localStorage.getItem("customerID");
    console.log("token: ", token, "customerID: ", customerID);
    if (token && customerID) {
      try {
        const response = await Services.SetLanguage(token, customerID, lng);
        console.log("SetLanguage başarılı:", response.data);
      } catch (error) {
        console.error("SetLanguage API çağrısı başarısız:", error);
      }
    }
    handleMenuClose();
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <NetworkStatusBar />
      <Grid item xs={3}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {left === "back" && (
            <IconButton
              aria-label="goBack"
              size="small"
              color="pure"
              onClick={() => goBack()}
            >
              <ArrowBackIosNew fontSize="inherit" />
            </IconButton>
          )}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {middle === "logo" ? (
            <img
              src={Logo}
              alt="easypoint logo"
              width={114}
              style={{ marginTop: 5 }}
            />
          ) : (
            <Typography
              variant="h6"
              component="h4"
              sx={{ lineHeight: "16.94px", fontSize: 14, fontWeight: 700 }}
            >
              {middle}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {right === "icon" && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img
                onClick={handleMenuClick}
                src={language === "tr" ? Turkiye : UnitedKingdom}
                width={30}
                style={{ marginRight: 10 }}
              />
              {!isLogin && (
                <Avatar
                  sx={{
                    bgcolor: "#FF6E60",
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                  }}
                  // onClick={handleMenuClick}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      lineHeight: "19.07px",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    {initials}
                  </Typography>
                </Avatar>
              )}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    dispatch(setLanguage("en"));
                    changeLanguage("en");
                  }}
                >
                  English
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(setLanguage("tr"));
                    changeLanguage("tr");
                  }}
                >
                  Türkçe
                </MenuItem>
              </Menu>
            </div>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
