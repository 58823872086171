import {
  Container,
  Button,
  Typography,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  PinDropOutlined,
  ScheduleOutlined,
  LockOpen,
} from "@mui/icons-material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Language from "../languages";

export default function PostDetailPage() {
  const navigate = useNavigate();
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const { index } = useParams();
  const location = useLocation();
  const item = location.state;
  const [dialogOpen, setDialogOpen] = useState(false);

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return moment(date).format("DD.MM.YYYY HH:mm");
  };

  const padWithZero = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = padWithZero(currentDate.getDate());
    const month = padWithZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    return `${day}.${month}.${year}`;
  };
  const deadlineDate = getCurrentDate();
  const deadlineTime = "22:00";

  useEffect(() => {
    console.log("item: ", item);
  }, []);

  return (
    <>
      <Container sx={{ paddingX: 0 }}>
        <Box sx={{ my: 4, mx: 3 }}>
          <HeaderComponent
            left="back"
            middle={
              item?.postID
                ? `${Language(mall, language, "package")} ${item.orderNumber}`
                : `${Language(mall, language, "request")}`
            }
            goBack={() => navigate(-1)}
            right="icon"
          />
        </Box>
        <Box
          sx={{ transform: "translateY(40%)", msTransform: "translateY(40%)" }}
        >
          <Typography
            textAlign="left"
            sx={{
              lineHeight: "16.94px",
              fontSize: 14,
              fontWeight: 600,
              marginX: 2,
            }}
          >
            {Language(mall, language, "deliveryInfo")}
          </Typography>
          <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginX: 2 }}
          >
            <Typography
              textAlign="left"
              color="#7D8290"
              sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
            >
              {Language(mall, language, "deliveryStatus")}
            </Typography>
            <Typography
              textAlign="left"
              color="orange"
              sx={{ lineHeight: "19.07px", fontSize: 14, fontWeight: 600 }}
            >
              {item.postID
                ? item.status === "3"
                  ? Language(mall, language, "inthestore")
                  : item.status === "4"
                  ? Language(mall, language, "ontheway")
                  : item.status === "5"
                  ? Language(mall, language, "pickup")
                  : item.status === "1"
                  ? Language(mall, language, "completed")
                  : item.status === "2"
                  ? Language(mall, language, "canceled")
                  : item.status === "10"
                  ? Language(mall, language, "locker")
                  : Language(mall, language, "unknown")
                : item.status === "2"
                ? Language(mall, language, "rejected")
                : item.status === "3"
                ? Language(mall, language, "awaitingStore")
                : item.status === "1"
                ? Language(mall, language, "approved")
                : Language(mall, language, "unknown")}
            </Typography>
          </Box>
          <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
          {item.postID && item.status !== "10" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginX: 2 }}
              >
                <Typography
                  textAlign="left"
                  color="#7D8290"
                  sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
                >
                  {Language(mall, language, "deliveryCode")}
                </Typography>
                <Typography
                  textAlign="left"
                  sx={{
                    lineHeight: "16.94px",
                    fontSize: 14,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LockOpen sx={{ marginRight: 1, width: 18 }} />{" "}
                  {item.deliveryPassword}
                </Typography>
              </Box>
              <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
            </>
          )}
          {item.status === "10" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginX: 2 }}
              >
                <Typography
                  textAlign="left"
                  color="#7D8290"
                  sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
                >
                  {Language(mall, language, "lockerNo")}
                </Typography>
                <Typography
                  textAlign="left"
                  sx={{
                    lineHeight: "16.94px",
                    fontSize: 14,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.postboxNo}
                </Typography>
              </Box>
              <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginX: 2 }}
              >
                <Typography
                  textAlign="left"
                  color="#7D8290"
                  sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
                >
                  {Language(mall, language, "locationCode")}
                </Typography>
                <Typography
                  textAlign="left"
                  sx={{
                    lineHeight: "16.94px",
                    fontSize: 14,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.deliveryTypeInfo.text}
                </Typography>
              </Box>
              <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
            </>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginX: 2 }}
          >
            <Typography
              textAlign="left"
              color="#7D8290"
              sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
            >
              {Language(mall, language, "creationDate")}
            </Typography>
            <Typography
              textAlign="left"
              sx={{
                lineHeight: "16.94px",
                fontSize: 14,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              {formatDateTime(item.createdDateTime)}
            </Typography>
          </Box>
          <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginX: 2 }}
          >
            <Typography
              textAlign="left"
              color="#7D8290"
              sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
            >
              {Language(mall, language, "store")}
            </Typography>
            <Typography
              textAlign="left"
              sx={{ lineHeight: "16.94px", fontSize: 14, fontWeight: 600 }}
            >
              {item.branchTitle}
            </Typography>
          </Box>
          <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginX: 2 }}
          >
            <Typography
              textAlign="left"
              color="#7D8290"
              sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
            >
              Easy Point
            </Typography>
            <div
              onClick={() => {
                if (item.mallTitle === "Galataport") {
                  setDialogOpen(true);
                } else {
                  return null;
                }
              }}
              style={{
                cursor: "pointer",
                color: "white",
                padding: 5,
                backgroundColor: "orange",
                borderRadius: 5,
              }}
            >
              <Typography
                textAlign="left"
                sx={{
                  lineHeight: "16.94px",
                  fontSize: 14,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PinDropOutlined sx={{ marginRight: 1, width: 18 }} />
                {Language(mall, language, "navigation")}
              </Typography>
            </div>
          </Box>
          <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginX: 2 }}
          >
            <Typography
              textAlign="left"
              color="#7D8290"
              sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
            >
              {Language(mall, language, "orderNo")}
            </Typography>
            <Typography
              textAlign="left"
              sx={{
                lineHeight: "16.94px",
                fontSize: 14,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              {item.orderID}
            </Typography>
          </Box>

          {item.postID && (
            <>
              <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginX: 2 }}
              >
                <Typography
                  textAlign="left"
                  color="#7D8290"
                  sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
                >
                  {Language(mall, language, "cargoTrackingNo")}
                </Typography>
                <Typography
                  textAlign="left"
                  sx={{
                    lineHeight: "16.94px",
                    fontSize: 14,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.postID}
                </Typography>
              </Box>
            </>
          )}
          {item.paymentType !== "mall-pay" && item.isPaid === "1" && (
            <>
              <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginX: 2 }}
              >
                <Typography
                  textAlign="left"
                  color="#7D8290"
                  sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
                >
                  {Language(mall, language, "paymentMethod")}
                </Typography>
                <Typography
                  textAlign="left"
                  sx={{
                    lineHeight: "16.94px",
                    fontSize: 14,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.paymentType === "cash"
                    ? Language(mall, language, "cash")
                    : item.paymentType === "credit-card"
                    ? Language(mall, language, "creditCard")
                    : item.paymentType === "online"
                    ? Language(mall, language, "online")
                    : Language(mall, language, "cashOrPost")}
                </Typography>
              </Box>

              <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginX: 2 }}
              >
                <Typography
                  textAlign="left"
                  color="green"
                  sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
                >
                  {Language(mall, language, "paymentStatus")}
                </Typography>
                <Typography
                  textAlign="left"
                  sx={{
                    lineHeight: "16.94px",
                    fontSize: 14,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    color: "green",
                  }}
                >
                  {Language(mall, language, "paid")}
                </Typography>
              </Box>
            </>
          )}
          {item?.paymentType !== "mall-pay" && item.isPaid === "0" && (
            <>
              <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginX: 2 }}
              >
                <Typography
                  textAlign="left"
                  color="#7D8290"
                  sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
                >
                  {Language(mall, language, "paymentStatus")}
                </Typography>
                <Typography
                  textAlign="left"
                  sx={{
                    lineHeight: "16.94px",
                    fontSize: 14,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    color: "red",
                  }}
                >
                  {Language(mall, language, "unpaid")}
                </Typography>
              </Box>
            </>
          )}
          <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
          {(item.status === "3" ||
            item.status === "4" ||
            item.status === "5") && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginX: 2 }}
              >
                <Typography
                  textAlign="left"
                  color="#7D8290"
                  sx={{ lineHeight: "14.52px", fontSize: 12, fontWeight: 600 }}
                >
                  {Language(mall, language, "deadlineForDelivery")}
                </Typography>
                <Typography
                  textAlign="left"
                  sx={{
                    lineHeight: "16.94px",
                    fontSize: 14,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {`${deadlineDate} ${deadlineTime}`}
                </Typography>
              </Box>
              <Divider sx={{ color: "#C1C6C9", marginY: 2 }} />
            </>
          )}
        </Box>
      </Container>
      <Dialog
        fullWidth
        fullScreen
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>{item.mallTitle}</DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
            title="map"
            width="100%"
            height="100%"
            style={{
              border: 0,
            }}
            src={`https://inmapper.com/galataport/?id=ID0D014&toTitle=Easy%20Point`}
          ></iframe>
        </DialogContent>
        <DialogActions
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            padding: "6px",
            background: "#d12626",
            borderRadius: "5px",
          }}
        >
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            style={{ color: "white" }}
          >
            {Language(mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
