export function encrypt(value = false, key = false) {
  if (typeof value === "string" && typeof key === "string") {
    const valueLength = value.length;
    const keyLength = key.length;
    let encryptedTextValue = 0;
    let encryptedValue = 0;

    const valueToEncrypt = valueLength >= 5 ? value.slice(-8) : value;
    const valueToEncryptLength = valueToEncrypt.length;

    for (let i = 0; i < keyLength; i++) {
      encryptedValue += key.charCodeAt(i);
    }

    for (let i = 0; i < valueToEncryptLength; i++) {
      encryptedTextValue += valueToEncrypt.charCodeAt(i) + encryptedValue;
    }

    return encryptedTextValue;
  }
  return null;
}
