import React, { useEffect, useState } from "react";

export default function NetworkStatusBar() {
  const [isOnline, setOnline] = useState(true);
  const [isVisible, setVisible] = useState(false);

  const updateNetworkStatus = () => {
    setOnline(navigator.onLine);
    if (!navigator.onLine) {
      setVisible(true);
    }
    if (navigator.onLine) {
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }
  };

  useEffect(() => {
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
      window.removeEventListener("load", updateNetworkStatus);
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, [navigator.onLine]);

  return (
    <div
      style={{
        width: "100%",
        height: "25px",
        backgroundColor: isOnline ? "#00a105" : "#d60000",
        top: isVisible ? "0" : "-25px",
        position: "absolute",
        zIndex: 99,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        transition: "top 0.3s ease-in-out",
      }}
    >
      <span style={{ color: "white" }}>
        {isOnline
          ? "İnternet bağlantısı sağlandı."
          : "İnternet bağlantınızı kontrol ediniz."}
      </span>
    </div>
  );
}
