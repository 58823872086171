import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#f5f5f5",
    },
    newRed: {
      main: "#FF6E60",
      contrastText: "#fff",
    },
    pure: {
      main: "#000000",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        inputProps: {
          style: {
            color: "#160033", // This sets the text field font color
          },
        },
      },
    },
  },
});

export default theme;
