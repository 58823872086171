import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Grid,
  Divider,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate, useLocation } from "react-router-dom";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Services from "../services/Services";
import Language from "../languages";
import { useSelector } from "react-redux";

export default function PackageSummaryPage() {
  const uri = localStorage.getItem("mallUri");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  const _mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const storedMall = localStorage.getItem("mall");
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({
    name: "Batuhan Göktaş",
    phone: "(+90) 535 088 8328",
    country: "Türkiye",
    packageCount: 3,
    roomNumber: 456,
  });
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("TRY");
  const [isChecked, setIsChecked] = useState(false); // Checkbox state
  const [order, setOrder] = useState();
  const [orders, setOrders] = useState([]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    Services.CustomerPosts(token, item?.customerID)
      .then((res) => {
        const filteredPosts = res.data.result.filter(
          (post) => post.orderID === item.orderID
        );
        setOrders(filteredPosts);
        console.log("filteredPosts: ", filteredPosts);
        let price = filteredPosts[0].deliveryTypeInfo.price;
        let totalPrice = 0;

        if (JSON.parse(storedMall)[0].calculationUnit === "service") {
          totalPrice = price;
        } else if (
          JSON.parse(storedMall)[0].calculationUnit === "per-package"
        ) {
          totalPrice = price * filteredPosts.length;
        }
        setOrder(filteredPosts[0]);
        setCurrency(filteredPosts[0].deliveryTypeInfo.currency);
        setPrice(price?.toString());
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent
          left="back"
          middle={"logo"}
          goBack={() => navigate(`/${uri}`)}
          right="icon"
        />

        {/* Paket Bilgileri */}
        <Box sx={{ marginTop: 4 }}>
          <Typography component="span" sx={{ color: "#1F2A37" }}>
            Easy Point{" "}
          </Typography>
          <Typography
            component="span"
            sx={{ fontWeight: "bolder", color: "#1F2A37" }}
          >
            Hands Free
          </Typography>

          <Divider sx={{ backgroundColor: "#EAEAEA", my: 1 }} />

          {/* <Typography
            sx={{
              color: "#9CA3AF",
              fontSize: "13px",
              fontWeight: 500,
              letterSpacing: -0.25,
            }}
          >
            Seçilen Paketler Easy Point Galataport'a Teslim Edilecek
          </Typography> */}
        </Box>

        <Box sx={{ marginTop: 3, flexDirection: "row", display: "flex" }}>
          <Typography
            sx={{ fontSize: "12px", color: "#6B7280", marginRight: "10px" }}
          >
            {Language(_mall, language, "YourPackagesToBeDelivered")}:
          </Typography>
          <InfoIcon fontSize="12px" sx={{ color: "#9CA3AF" }} />
        </Box>

        <Grid
          container
          alignItems="stretch"
          spacing={2}
          sx={{
            mt: 2,
            "@media (max-width: 320px)": {
              justifyContent: "center",
              textAlign: "center",
            },
          }}
        >
          <Grid item>
            <Box
              sx={{
                width: 70,
                height: 70,
                borderRadius: "6px",
                backgroundColor: "#E5E7EB",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ShoppingBagIcon sx={{ color: "#4B5563", fontSize: 40 }} />
            </Box>
          </Grid>

          <Grid item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
                {Language(_mall, language, "shopBag")}
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: "11px" }}>
                {order?.branchTitle}
              </Typography>
              {/* <Typography
                sx={{ color: "#9CA3AF", fontWeight: "400", fontSize: "11px" }}
              >
                Paket Numaraları:
              </Typography>
              {orders?.map((item, index) => {
                return (
                  <Typography
                    key={index}
                    sx={{
                      color: "#9CA3AF",
                      fontWeight: "400",
                      fontSize: "11px",
                    }}
                  >
                    {item?.orderNumber}
                  </Typography>
                );
              })} */}
            </Box>
          </Grid>

          <Grid item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                textAlign: "right",
                "@media (max-width: 320px)": {
                  textAlign: "center",
                },
              }}
            >
              <Typography
                sx={{
                  color: "#6B7280",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {Language(_mall, language, "packageCount")}: {orders?.length}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#1F2A37",
                }}
              >
                {price} {currency}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Teslim Eden Kişi Bilgileri */}
        {/* <Box sx={{ marginTop: 4, flexDirection: "row", display: "flex" }}>
          <Typography
            sx={{ fontSize: "12px", color: "#6B7280", marginRight: "10px" }}
          >
            İletişim Bilgileri:
          </Typography>
          <InfoIcon fontSize="12px" sx={{ color: "#9CA3AF" }} />
        </Box>

        <Box
          sx={{
            border: "1px solid #E5E7EB",
            borderRadius: "8px",
            padding: "16px",
            marginTop: "16px",
            position: "relative",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              {isEditing ? (
                <>
                  <TextField
                    fullWidth
                    name="name"
                    label="Ad Soyad"
                    value={userData.name}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    fullWidth
                    name="phone"
                    label="Telefon No"
                    value={userData.phone}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    fullWidth
                    name="country"
                    label="Ülke"
                    value={userData.country}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    fullWidth
                    name="roomNumber"
                    label="Oda No"
                    value={userData.roomNumber}
                    onChange={handleChange}
                  />
                </>
              ) : (
                <>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {userData.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#080E1E99",
                      fontSize: "11px",
                      fontWeight: "400",
                      marginTop: "5px",
                    }}
                  >
                    Telefon No: {userData.phone}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#080E1E99",
                      fontSize: "11px",
                      fontWeight: "400",
                      marginTop: "5px",
                    }}
                  >
                    Ülke: {userData.country}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#080E1E99",
                      fontSize: "11px",
                      fontWeight: "400",
                      marginTop: "5px",
                    }}
                  >
                    Paket Sayısı: {userData.packageCount}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#080E1E99",
                      fontSize: "11px",
                      fontWeight: "400",
                      marginTop: "5px",
                    }}
                  >
                    Oda No: {userData.roomNumber}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignSelf: "flex-start",
              }}
            >
              <IconButton
                onClick={handleEditClick}
                sx={{ position: "absolute", right: 16 }}
              >
                {isEditing ? <SaveIcon /> : <EditIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </Box> */}

        {/* Onay Checkbox */}
        <Box sx={{ marginTop: 4 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                sx={{
                  color: "#DA3831",
                  "&.Mui-checked": {
                    color: "#DA3831",
                  },
                }}
              />
            }
            label={
              <Typography sx={{ color: "#6B7280", fontSize: "12px" }}>
                {Language(_mall, language, "infoConfirm")}
              </Typography>
            }
          />
        </Box>
        <Button
          fullWidth
          disabled={!isChecked}
          onClick={() => navigate("/payment", { state: orders })}
          variant="contained"
          color="newRed"
          sx={{ marginTop: "110px" }}
          startIcon={<i className="fa fa-credit-card" />}
        >
          {Language(_mall, language, "goToPay")}
        </Button>
      </Box>
      <Typography
        variant="body2"
        textAlign="center"
        sx={{ mt: 4, color: "#888" }}
      >
        Copyright ©Easy Point 2024.
      </Typography>
    </Container>
  );
}
