import { Container, Typography, Button, Box } from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate } from "react-router-dom";
import Rejection from "../assets/images/flower-in-pot.png";

export default function PostFailurePage() {
  const navigate = useNavigate();

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent middle="logo" />

        <Box
          sx={{
            marginTop: 15,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Rejection} alt="easyflower" width={90} height={140} />
          <Typography
            sx={{
              lineHeight: "21.78px",
              fontSize: 18,
              fontWeight: 700,
              marginTop: 5,
            }}
          >
            Rejection
          </Typography>
          <Typography
            sx={{
              lineHeight: "16.94px",
              fontSize: 14,
              fontWeight: 400,
              marginTop: 3,
            }}
          >
            Rejection Message
          </Typography>
          <Button
            variant="contained"
            color="newRed"
            fullWidth
            sx={{ paddingY: 1.2, marginTop: 10 }}
            onClick={() => navigate("/create-request")}
          >
            Ana Sayfaya Dön
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
