import {
  Container,
  Typography,
  Avatar,
  IconButton,
  Button,
  Box,
  Chip,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate } from "react-router-dom";
import Package1 from "../assets/images/package1.png";
import { useState, useEffect } from "react";
import Services from "../services/Services";
import {
  AddCircleOutline,
  ArrowForwardIos,
  LocationOnOutlined,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setProjectName, setMall } from "../redux/appReducer";
import Language from "../languages";
import { setLanguage } from "../redux/appReducer";
import { encrypt } from "../utils/encrypt";

export default function PostListingPage() {
  const projectName = useSelector((state) => state.projectName);
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const hashedCode = localStorage.getItem("hashedCode");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const phoneNumber = "0850 214 0044";
  const [token, setToken] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [user, setUser] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [requests, setRequests] = useState([]);
  const [storesPackages, setStoresPackages] = useState([]);
  const [porterPackages, setPorterPackages] = useState([]);
  const [readyPackages, setReadyPackages] = useState([]);
  const [pastRequests, setPastRequests] = useState([]);
  const [allRequests, setAllRequests] = useState([]);
  const [storeNames, setStoreNames] = useState({});
  const [storedMallTitle, setStoredMallTitle] = useState("");
  // const [mallID, setMallID] = useState("");
  const [lastFetchTime, setLastFetchTime] = useState(
    new Date().toLocaleString("en-GB")
  );
  const [forceRefresh, setForceRefresh] = useState(false);

  const handlePhoneNumberClick = () => {
    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      const textField = document.createElement("textarea");
      textField.innerText = phoneNumber;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      document.body.removeChild(textField);
    }
  };

  const findLocationID = (orderID) => {
    const matchedRequest = allRequests.find(
      (request) => request.orderID === orderID
    );
    return matchedRequest ? matchedRequest.locationID : "Lokasyon Bulunamadı";
  };

  const findStoreName = (branchID) => {
    Services.GetLocationList(token, branchID).then((res) => {
      const matchedItem = res.data.result.find(
        (item) => item.branchID === branchID
      );
      setStoreNames((prevStoreNames) => ({
        ...prevStoreNames,
        [branchID]: matchedItem.title,
      }));
    });
    // .catch((err) => // console.log(err));
  };

  useEffect(() => {
    pastRequests.forEach((item) => {
      findStoreName(item.branchID);
    });
  }, [pastRequests]);

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return moment(date).format("DD.MM.YYYY HH:mm");
  };

  const findPosts = (order) => {
    const matchedPosts = [];
    for (const post of allPosts) {
      if (post.orderID === order.orderID) {
        matchedPosts.push(post);
      }
    }
    navigate("past-request-details", {
      state: {
        postList: matchedPosts,
        locationID: order.locationID,
      },
    });
  };

  const handleRefresh = () => {
    setForceRefresh(true);
  };

  useEffect(() => {
    if (forceRefresh) {
      fetchDataAndRequests();
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  const fetchDatas = (token, customerID, mallID) => {
    //// console.log("posts için mallID: ", mallID);
    Services.CustomerPosts(token, customerID, mallID)
      .then((res) => {
        const packages = res.data.result;
        const stores = packages?.filter((item) => item.status === "3");
        const porter = packages?.filter(
          (item) =>
            item.status === "4" ||
            item.status === "7" ||
            item.status === "8" ||
            item.status === "9"
        );
        const ready = packages?.filter(
          (item) => item.status === "5" || item.status === "10"
        );

        setAllPosts(packages);
        setStoresPackages(stores);
        setPorterPackages(porter);
        setReadyPackages(ready);
      })
      .finally(() => setShowLoading(false));
  };

  const fetchCustomerOrders = (
    storedToken,
    storedCustomerID,
    mallID,
    orderId
  ) => {
    if (orderId) {
      Services.CustomerOrders(storedToken, storedCustomerID).then(
        (response) => {
          if (response.data.status) {
            setAllRequests(response.data.result);
            const matchedOrder = response.data.result.find(
              (obj) => obj.orderID === orderId
            );
            navigate("/create-new", { state: matchedOrder });
          } else {
            setRequests([]);
            setPastRequests([]);
            setShowLoading(false);
          }
        }
      );
    } else {
      //// console.log("orders için mallID: ", mallID);
      Services.CustomerOrdersByMallID(storedToken, storedCustomerID, mallID)
        .then((response) => {
          if (response.data.status) {
            setAllRequests(response.data.result);
            const currentDate = new Date().toLocaleDateString("tr-TR");
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            const yesterdayDate = yesterday.toLocaleDateString("tr-TR");

            const completedList = response.data.result.filter(
              (item) => item.isCompleted === "1"
            );

            const normalStatusList = response.data.result.filter(
              (item) =>
                (item.status === "3" || item.status === "6") &&
                item.isCompleted === "0"
            );

            const filteredRequests = response.data.result.filter(
              (item) =>
                (item.status === "1" || item.status === "2") &&
                new Date(item.createdDateTime * 1000).toLocaleDateString(
                  "tr-TR"
                ) === currentDate
            );

            const pastRejecteds = response.data.result.filter(
              (item) =>
                item.status === "2" &&
                new Date(+item.createdDateTime * 1000).toLocaleDateString(
                  "tr-TR"
                ) < yesterdayDate
            );

            let combinedList = [];
            let pastList = [];

            if (normalStatusList?.length > 0 && filteredRequests?.length > 0) {
              combinedList = normalStatusList.concat(filteredRequests);
            } else {
              combinedList =
                normalStatusList?.length > 0
                  ? normalStatusList
                  : filteredRequests;
            }

            combinedList = combinedList.filter(
              (item) => item.isCompleted !== "1"
            );

            setRequests(combinedList);
            if (completedList?.length > 0 && pastRejecteds?.length > 0) {
              pastList = completedList.concat(pastRejecteds);
            } else {
              pastList =
                completedList?.length > 0 ? completedList : pastRejecteds;
            }
            setPastRequests(pastList);
            setShowLoading(false);
          } else {
            setRequests([]);
            setPastRequests([]);
            setShowLoading(false);
          }
        })
        .catch((error) => {
          // console.log(error);
          setRequests([]);
          setShowLoading(false);
        });
    }
  };

  const fetchDataAndRequests = () => {
    setShowLoading(true);
    setLastFetchTime(new Date().toLocaleString("en-GB"));
    const match = window.location.pathname.match(/\/([^\/]*)\//);
    const urlParams = new URLSearchParams(window.location.search);
    const url_token = urlParams.get("token");
    const languageParam = urlParams.get("language") || language;
    const fullURL = window.location.search;

    const userIDIndex = fullURL.indexOf("userID=");
    let url_customerID = "";

    if (userIDIndex !== -1) {
      const endIndex = fullURL.indexOf("&", userIDIndex + "userID=".length);
      url_customerID =
        endIndex === -1
          ? fullURL.substring(userIDIndex + "userID=".length)
          : fullURL.substring(userIDIndex + "userID=".length, endIndex);
    }

    // akıllı avm
    if (url_token && url_customerID) {
      //// console.log("url token: ", url_token, "url_customerID: ", url_customerID);
      localStorage.setItem("mallUri", match[1]);
      if (match && match[1] === "galataport") {
        dispatch(setProjectName("Bags-Free"));
        dispatch(setMall("galataport"));
      } else {
        dispatch(setProjectName("Hands Free"));
        dispatch(setMall("default"));
      }
      setToken(url_token);
      dispatch(setLanguage(languageParam));
      Services.ThirdPartyCustomerQuery(
        url_token,
        url_customerID,
        match[1],
        languageParam
      )
        .then((res) => {
          //// console.log("third party response: ", res.data);
          localStorage.setItem("token", url_token);
          localStorage.setItem("name", res.data.result.name);
          localStorage.setItem("lastname", res.data.result.lastname);
          localStorage.setItem("phone", res.data.result.phone);
          localStorage.setItem("customerID", res.data.result.customerID);
          localStorage.setItem("email", res.data.result.email);
          localStorage.setItem(
            "hashedCode",
            encrypt(res.data.result.phone, "handsfreeAvm*24")
          );
          setUser(true);
          // avm mağazaları kaydedildi
          Services.GetTokenInfo(url_token)
            .then((response) => {
              fetchDatas(
                url_token,
                res.data.result.customerID,
                response.data.result.mall[0].id
              );
              fetchCustomerOrders(
                url_token,
                res.data.result.customerID,
                response.data.result.mall[0].id
              );
              localStorage.setItem(
                "mall",
                JSON.stringify(response.data.result.mall)
              );
            })
            .catch((error) => {
              // console.log(error);
              localStorage.setItem("mall", JSON.stringify(""));
            });
        })
        .catch((err) => {
          // console.log(err);
          localStorage.setItem("name", "");
          localStorage.setItem("lastname", "");
          localStorage.setItem("phone", "");
          localStorage.setItem("customerID", "");
          localStorage.setItem("email", "");
          localStorage.setItem("mall", JSON.stringify(""));
          setShowLoading(false);
        });
    }
    // akıllı avm değil
    else {
      //// console.log("Akıllı avm değil");
      Services.GetDefaultToken()
        .then((res) => {
          const newToken = res.data.result.token;
          setToken(newToken);
          localStorage.setItem("token", res.data.result.token);

          const url = window.location.href;
          const regex = /\/([^\/]*)$/;
          const onlyAvm = url.match(regex);
          const urlParts = url.split("/");
          const path = urlParts[urlParts.length - 2]; // path'i al
          const orderId = urlParts[urlParts.length - 1]; // orderId'yi al
          //// console.log("path: ", path, "orderID: ", orderId);
          // order-confirm mi diye kontrol edilmeli
          if (path && path === "order-confirm") {
            // console.log("order confirm'e girdi");
            const controlPhone = localStorage.getItem("phone");
            if (
              controlPhone &&
              encrypt(controlPhone, "handsfreeAvm*24") == hashedCode
            ) {
              Services.GetCustomer(res.data.result.token, controlPhone)
                .then((_response) => {
                  // console.log("get customer: ", _response.data);
                  localStorage.setItem("name", _response.data.result.name);
                  localStorage.setItem(
                    "lastname",
                    _response.data.result.lastname
                  );
                  localStorage.setItem("phone", _response.data.result.phone);
                  localStorage.setItem(
                    "customerID",
                    _response.data.result.customerID
                  );
                  dispatch(setLanguage(_response.data.result.defaultLanguage)); // Burada gelen language değeri ile güncelleyeceğiz.
                  fetchCustomerOrders(
                    newToken,
                    _response.data.result.customerID,
                    "",
                    orderId
                  );
                })
                .catch((err) => {
                  // console.log("get customer hata: ", err);
                });
            } else {
              userNotFound();
              setShowLoading(false);
            }
          } else if (orderId && orderId === "null") {
            localStorage.removeItem("mall");
            localStorage.removeItem("mallUri");
            navigate("select-mall");
          } else {
            if (onlyAvm && onlyAvm[1]) {
              const afterSlashValue = onlyAvm[1];
              // console.log("onlyavm: ", onlyAvm[1]);
              if (onlyAvm[1] === "galataport") {
                dispatch(setProjectName("Bags-Free"));
                dispatch(setMall("galataport"));
              } else {
                dispatch(setProjectName("Hands Free"));
                dispatch(setMall("default"));
              }
              localStorage.setItem("mallUri", onlyAvm[1]);
              Services.GetMallToken(newToken, afterSlashValue)
                .then((response) => {
                  localStorage.setItem(
                    "mall",
                    JSON.stringify(response.data.result.mall)
                  );
                  const controlPhone = localStorage.getItem("phone");
                  if (controlPhone) {
                    //  console.log(
                    //   "only avm: ",
                    //   encrypt(controlPhone, "handsfreeAvm*24"),
                    //   hashedCode
                    // );
                    if (
                      encrypt(controlPhone, "handsfreeAvm*24") == hashedCode
                    ) {
                      Services.GetCustomer(newToken, controlPhone)
                        .then((_response) => {
                          // console.log("get customer: ", _response.data);
                          // console.log("only avm'ye girdi");

                          localStorage.setItem(
                            "name",
                            _response.data.result.name
                          );
                          localStorage.setItem(
                            "lastname",
                            _response.data.result.lastname
                          );
                          localStorage.setItem(
                            "phone",
                            _response.data.result.phone
                          );
                          localStorage.setItem(
                            "customerID",
                            _response.data.result.customerID
                          );
                          dispatch(
                            setLanguage(_response.data.result.defaultLanguage)
                          );
                          setShowLoading(false);
                          setUser(true);
                          fetchDatas(
                            newToken,
                            _response.data.result.customerID,
                            response.data.result.mall[0].id
                          );
                          if (path && path === "order-confirm") {
                            fetchCustomerOrders(
                              newToken,
                              _response.data.result.customerID,
                              response.data.result.mall[0].id,
                              orderId
                            );
                          } else {
                            fetchCustomerOrders(
                              newToken,
                              _response.data.result.customerID,
                              response.data.result.mall[0].id
                            );
                          }
                        })
                        .catch((err) => {
                          // console.log("get customer hata: ", err);
                          setShowLoading(false);
                        });
                    } else {
                      // Sorunlu deneme
                      userNotFound();
                      setShowLoading(false);
                    }
                  } else {
                    setShowLoading(false);
                  }
                })
                .catch(() => {
                  // console.log("mall token hata");
                  setShowLoading(false);
                });
            } else {
              localStorage.removeItem("mall");
              localStorage.removeItem("mallUri");
              navigate("select-mall");
              setShowLoading(false);
            }
          }
        })
        .catch(() => {
          // console.log("default token hata");
          setShowLoading(false);
        });
    }
  };

  const userNotFound = () => {
    setUser(false);
    navigate("login");
    localStorage.clear();
  };

  useEffect(() => {
    if (!user) {
      const intervalId = setInterval(() => {
        fetchDataAndRequests();
      }, 30000);
      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    fetchDataAndRequests();
    const mallData = localStorage.getItem("mall");
    if (mallData) {
      const parsedMallData = JSON.parse(mallData);
      if (parsedMallData && parsedMallData?.length > 0) {
        setStoredMallTitle(parsedMallData[0].title);
      }
    }
  }, []);

  useEffect(() => {
    // user control
    Services.GetDefaultToken()
      .then((res) => {
        const newToken = res.data.result.token;
        setToken(newToken);
        localStorage.setItem("token", res.data.result.token);
        const controlPhone = localStorage.getItem("phone");
        // console.log("useEffect Control: ", controlPhone);
        if (
          controlPhone &&
          encrypt(controlPhone, "handsfreeAvm*24") == hashedCode
        ) {
          setUser(true);
        } else {
          setUser(false);
          navigate("login");
        }
        // setShowLoading(false);
      })
      .catch(() => {
        setShowLoading(false);
      });
  }, []);

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        {user ? (
          <HeaderComponent middle="logo" right="icon" />
        ) : (
          <HeaderComponent middle="logo" right="icon" isLogin={true} />
        )}

        {showLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            {user ? (
              <>
                <Box sx={{ marginTop: 5 }}>
                  <Chip
                    color="newRed"
                    variant="filled"
                    onClick={() => navigate("create-request")}
                    onDelete={() => navigate("create-request")}
                    label={
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#FFFFFF",
                          lineHeight: "16.94px",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        {/* {t("createHandsfree").replace(
                          "{projectName}",
                          projectName
                        )} */}
                        {Language(
                          mall,
                          language,
                          "createHandsfree",
                          projectName
                        )}
                      </Typography>
                    }
                    deleteIcon={
                      <Box display="flex" justifyContent="center">
                        <AddCircleOutline sx={{ color: "#fff" }} />
                      </Box>
                    }
                    sx={{
                      width: "100%",
                      height: 54,
                      justifyContent: "space-between",
                      paddingX: 1,
                      marginBottom: 5,
                      "&:hover": {
                        backgroundColor: "rgba(255, 110, 96, 0.9)",
                      },
                    }}
                  />

                  <Typography
                    variant="subtitle1"
                    sx={{
                      lineHeight: "16.34px",
                      fontSize: 12,
                      fontWeight: 700,
                      marginBottom: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {Language(mall, language, "handsfreeRequests", projectName)}
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        paddingBlock: 6,
                        justifyContent: "center",
                        border: "1px solid",
                        borderRadius: 5,
                        paddingRight: 15,
                        paddingLeft: 12,
                        backgroundColor: "rgba(249, 249, 249, 1)",
                        borderColor: "rgba(208, 213, 221, 1)",
                      }}
                    >
                      <LocationOnOutlined
                        style={{
                          paddingLeft: -2,
                          marginRight: 1,
                          color: "rgba(255, 110, 97, 1)",
                        }}
                      />
                      <Typography
                        style={{
                          color: "rgba(117, 117, 117, 1)",
                          fontWeight: "600",
                          fontSize: 13,
                        }}
                      >
                        {storedMallTitle}
                      </Typography>
                    </div>
                  </Typography>

                  {requests?.length > 0 ? (
                    <>
                      {requests.map((item, index) => (
                        <Box
                          key={index}
                          onClick={() => {
                            item.status === "6"
                              ? navigate("/create-new", { state: item })
                              : navigate("/post-details", {
                                  state: {
                                    ...item,
                                    locationID: findLocationID(item.orderID),
                                  },
                                });
                          }}
                          style={{
                            display: "flex",
                            height: 73,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor: "#ffffff",
                            elevation: 4,
                            border: "1px solid  #C1C6C9",
                            shadowColor: "#092420",
                            shadowOpacity: 0.13,
                            shadowOffset: {
                              width: 0,
                              height: 4,
                            },
                            shadowRadius: 10,
                            borderRadius: 8,
                            alignItems: "center",
                            marginBottom: 20,
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <img
                              src={Package1}
                              alt="easypoint logo"
                              width={25}
                              height={25}
                              style={{ marginLeft: 20, marginRight: 20 }}
                            />

                            <Box>
                              <Typography
                                sx={{
                                  lineHeight: "16.94px",
                                  fontSize: 14,
                                  fontWeight: 700,
                                  marginBottom: "5px",
                                }}
                              >
                                {item.branchTitle}
                              </Typography>
                              <Typography
                                sx={{
                                  lineHeight: "16.94px",
                                  fontSize: 12,
                                  marginBottom: "5px",
                                  color: "gray",
                                }}
                              >
                                {formatDateTime(item.createdDateTime)}
                              </Typography>
                              <Typography
                                sx={{
                                  color:
                                    item.status === "2"
                                      ? "red"
                                      : item.status === "3" ||
                                        item.status === "6"
                                      ? "#8f4b07"
                                      : "#a86b32",
                                  lineHeight: "14.52px",
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {item.status === "3" &&
                                  Language(mall, language, "awaitingStore")}
                                {item.status === "6" &&
                                  Language(mall, language, "awaitingYou")}

                                {item.status === "2" &&
                                  Language(mall, language, "rejected")}
                                {item.status === "1" &&
                                  Language(mall, language, "approved")}
                              </Typography>
                            </Box>
                          </Box>

                          <IconButton
                            aria-label="goBack"
                            size="small"
                            color="pure"
                            sx={{ marginRight: 1 }}
                            // onClick={() => {
                            //   item.status === "6"
                            //     ? navigate("/create-new", { state: item })
                            //     : navigate(`/post-details/${index + 1}`, {
                            //         state: item,
                            //       });
                            // }}
                          >
                            <ArrowForwardIos fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        lineHeight: "16.34px",
                        fontSize: 12,
                        fontWeight: 600,
                        marginBottom: 5,
                        color: "gray",
                      }}
                    >
                      {/* {t("emptyHandsfreeRequests").replace(
                        "{projectName}",
                        projectName
                      )} */}
                      {Language(
                        mall,
                        language,
                        "emptyHandsfreeRequests",
                        projectName
                      )}
                    </Typography>
                  )}

                  <Typography
                    variant="subtitle1"
                    sx={{
                      lineHeight: "16.34px",
                      fontSize: 12,
                      fontWeight: 700,
                      marginBottom: 2,
                    }}
                  >
                    {/* {t("storePackages")} */}
                    {Language(mall, language, "storePackages")}
                  </Typography>

                  {storesPackages?.length > 0 ? (
                    <>
                      {" "}
                      {storesPackages?.map((item, index) => (
                        <Box
                          key={index}
                          onClick={() =>
                            navigate("/post-details", {
                              state: {
                                ...item,
                                locationID: findLocationID(item.orderID),
                              },
                            })
                          }
                          style={{
                            display: "flex",
                            height: 73,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor: "#ffffff",
                            elevation: 4,
                            border: "1px solid  #C1C6C9",
                            shadowColor: "#092420",
                            shadowOpacity: 0.13,
                            shadowOffset: {
                              width: 0,
                              height: 4,
                            },
                            shadowRadius: 10,
                            borderRadius: 8,
                            alignItems: "center",
                            marginBottom: 20,
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Avatar
                              sx={{
                                bgcolor: "#B7CDC1",
                                width: 41,
                                height: 41,
                                marginLeft: 2,
                                marginRight: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#000000",
                                  lineHeight: "19.07px",
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                              >
                                H
                              </Typography>
                            </Avatar>

                            <Box>
                              <Typography
                                sx={{
                                  lineHeight: "19.36px",
                                  fontSize: 16,
                                  fontWeight: 700,
                                  marginBottom: 1,
                                }}
                              >
                                {`${item.branchTitle} - (${item.orderNumber})`}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#0c1082",
                                  lineHeight: "14.52px",
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {/* {t("inthestore")} */}
                                {Language(mall, language, "inthestore")}
                              </Typography>
                            </Box>
                          </Box>

                          <IconButton
                            aria-label="goBack"
                            size="small"
                            color="pure"
                            sx={{ marginRight: 1 }}
                          >
                            <ArrowForwardIos fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Typography
                      sx={{
                        lineHeight: "16.34px",
                        fontSize: 12,
                        fontWeight: 600,
                        marginBottom: 5,
                        color: "gray",
                      }}
                    >
                      {/* {t("emptyStorePackages")} */}
                      {Language(mall, language, "emptyStorePackages")}
                    </Typography>
                  )}

                  <Typography
                    variant="subtitle1"
                    sx={{
                      lineHeight: "16.34px",
                      fontSize: 12,
                      fontWeight: 700,
                      marginBottom: 2,
                    }}
                  >
                    {/* {t("onthewayPackges")} */}
                    {Language(mall, language, "onthewayPackges")}
                  </Typography>

                  {porterPackages?.length > 0 ? (
                    <>
                      {porterPackages.map((item, index) => (
                        <Box
                          key={index}
                          onClick={() =>
                            navigate("/post-details", {
                              state: {
                                ...item,
                                locationID: findLocationID(item.orderID),
                              },
                            })
                          }
                          style={{
                            display: "flex",
                            height: 73,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor: "#ffffff",
                            elevation: 4,
                            border: "1px solid  #C1C6C9",
                            shadowColor: "#092420",
                            shadowOpacity: 0.13,
                            shadowOffset: {
                              width: 0,
                              height: 4,
                            },
                            shadowRadius: 10,
                            borderRadius: 8,
                            alignItems: "center",
                            marginBottom: 20,
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Avatar
                              sx={{
                                bgcolor: "#B7CDC1",
                                width: 41,
                                height: 41,
                                marginLeft: 2,
                                marginRight: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#000000",
                                  lineHeight: "19.07px",
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                              >
                                H
                              </Typography>
                            </Avatar>

                            <Box>
                              <Typography
                                sx={{
                                  lineHeight: "19.36px",
                                  fontSize: 16,
                                  fontWeight: 700,
                                  marginBottom: 1,
                                }}
                              >
                                {`${item.branchTitle} - (${item.orderNumber})`}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#0c1082",
                                  lineHeight: "14.52px",
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {/* {t("ontheway")}! */}
                                {item.status === "9"
                                  ? Language(
                                      mall,
                                      language,
                                      "courierOnDelivery"
                                    )
                                  : Language(mall, language, "ontheway")}
                              </Typography>
                            </Box>
                          </Box>

                          <IconButton
                            aria-label="goBack"
                            size="small"
                            color="pure"
                            sx={{ marginRight: 1 }}
                          >
                            <ArrowForwardIos fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Typography
                      sx={{
                        lineHeight: "16.34px",
                        fontSize: 12,
                        fontWeight: 600,
                        marginBottom: 5,
                        color: "gray",
                      }}
                    >
                      {/* {t("emptyOnthewayPackages")} */}
                      {Language(mall, language, "emptyOnthewayPackages")}
                    </Typography>
                  )}

                  <Typography
                    variant="subtitle1"
                    sx={{
                      lineHeight: "16.34px",
                      fontSize: 12,
                      fontWeight: 700,
                      marginBottom: 2,
                    }}
                  >
                    {/* {t("readyPackages")} */}
                    {Language(mall, language, "readyPackages")}
                  </Typography>

                  {readyPackages?.length > 0 ? (
                    <>
                      {readyPackages.map((item, index) => (
                        <Box
                          key={index}
                          onClick={() =>
                            navigate("/post-details", {
                              state: {
                                ...item,
                                locationID: findLocationID(item.orderID),
                              },
                            })
                          }
                          style={{
                            display: "flex",
                            height: 73,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor: "#ffffff",
                            elevation: 4,
                            border: "1px solid  #C1C6C9",
                            shadowColor: "#092420",
                            shadowOpacity: 0.13,
                            shadowOffset: {
                              width: 0,
                              height: 4,
                            },
                            shadowRadius: 10,
                            borderRadius: 8,
                            alignItems: "center",
                            marginBottom: 20,
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <img
                              src={Package1}
                              alt="easypoint logo"
                              width={25}
                              height={25}
                              style={{ marginLeft: 20, marginRight: 20 }}
                            />

                            <Box>
                              <Typography
                                sx={{
                                  lineHeight: "16.94px",
                                  fontSize: 14,
                                  fontWeight: 700,
                                  marginBottom: 1,
                                }}
                              >
                                {`${item.branchTitle} - (${item.orderNumber})`}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#105919",
                                  lineHeight: "14.52px",
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {/* {t("pickup")} */}
                                {Language(mall, language, "pickup")}{" "}
                                {item.status === "10" &&
                                  `(${Language(mall, language, "locker")})`}
                              </Typography>
                            </Box>
                          </Box>

                          <IconButton
                            aria-label="goBack"
                            size="small"
                            color="pure"
                            sx={{ marginRight: 1 }}
                          >
                            <ArrowForwardIos fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Typography
                      sx={{
                        lineHeight: "16.34px",
                        fontSize: 12,
                        fontWeight: 600,
                        marginBottom: 5,
                        color: "gray",
                      }}
                    >
                      {/* {t("emptyReadyPackages")} */}
                      {Language(mall, language, "emptyReadyPackages")}
                    </Typography>
                  )}

                  <Typography
                    variant="subtitle1"
                    sx={{
                      lineHeight: "16.34px",
                      fontSize: 12,
                      fontWeight: 700,
                      marginBottom: 2,
                    }}
                  >
                    {/* {t("pastRequests")} */}
                    {Language(mall, language, "pastRequests")}
                  </Typography>

                  {pastRequests?.length > 0 ? (
                    <>
                      {pastRequests?.map((item, index) => (
                        <Box
                          key={index}
                          onClick={() => {
                            if (item.status === "1") {
                              findPosts(item);
                            }
                          }}
                          style={{
                            display: "flex",
                            height: 100,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor: "#ffffff",
                            elevation: 4,
                            border: "1px solid  #C1C6C9",
                            shadowColor: "#092420",
                            shadowOpacity: 0.13,
                            shadowOffset: {
                              width: 0,
                              height: 4,
                            },
                            shadowRadius: 10,
                            borderRadius: 8,
                            alignItems: "center",
                            marginBottom: 20,
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Avatar
                              sx={{
                                bgcolor: "#B7CDC1",
                                width: 41,
                                height: 41,
                                marginLeft: 2,
                                marginRight: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#000000",
                                  lineHeight: "19.07px",
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                              >
                                H
                              </Typography>
                            </Avatar>

                            <Box>
                              <Typography
                                sx={{
                                  lineHeight: "19.36px",
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                              >
                                {storeNames[item.branchID] || ""}
                              </Typography>

                              <Typography
                                sx={{
                                  lineHeight: "19.36px",
                                  fontSize: 12,
                                }}
                              >
                                {formatDateTime(item.createdDateTime)}
                              </Typography>

                              <Typography
                                sx={{
                                  lineHeight: "19.36px",
                                  fontSize: 12,
                                }}
                              >
                                ({item.orderID})
                              </Typography>
                              <Typography
                                sx={{
                                  color: item.status === "1" ? "black" : "red",
                                  lineHeight: "14.52px",
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {item.status === "1"
                                  ? Language(mall, language, "completed")
                                  : Language(mall, language, "canceled")}
                              </Typography>
                            </Box>
                          </Box>

                          {item.status === "1" && (
                            <IconButton
                              aria-label="goBack"
                              size="small"
                              color="pure"
                              sx={{ marginRight: 1 }}
                            >
                              <ArrowForwardIos fontSize="inherit" />
                            </IconButton>
                          )}
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Typography
                      sx={{
                        lineHeight: "16.34px",
                        fontSize: 12,
                        fontWeight: 600,
                        marginBottom: 20,
                        color: "gray",
                      }}
                    >
                      {/* {t("emptyPastRequests")} */}
                      {Language(mall, language, "emptyPastRequests")}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    onClick={handlePhoneNumberClick}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingInline: 10,
                      paddingBlock: 5,
                      backgroundColor: "#FF6E60",
                      borderRadius: 5,
                      alignItems: "center",
                      color: "white",
                      cursor: "pointer",
                      marginBottom: 10,
                    }}
                  >
                    <LocalPhoneIcon fontSize="small" />

                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "white",
                        fontSize: 12,
                        marginLeft: "5px",
                      }}
                    >
                      {phoneNumber}
                    </Typography>
                  </div>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "black",
                      fontSize: 12,
                      textAlign: "center",
                      marginBottom: 1,
                    }}
                  >
                    {/* {t("lastUpdate")}: {lastFetchTime} */}
                    {Language(mall, language, "lastUpdate")}: {lastFetchTime}
                  </Typography>
                  <IconButton
                    aria-label="refresh"
                    size="small"
                    color="pure"
                    sx={{
                      marginLeft: 1,
                      backgroundColor: "#FF6E60",
                      borderRadius: 2,
                      paddingInline: 2,
                      marginBottom: 1,
                    }}
                    onClick={handleRefresh}
                  >
                    <RefreshIcon
                      fontSize="inherit"
                      style={{ color: "white" }}
                    />{" "}
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "white",
                        fontSize: 16,
                        textAlign: "center",
                        marginLeft: 0.4,
                      }}
                    >
                      {/* {t("refresh")} */}
                      {Language(mall, language, "refresh")}
                    </Typography>
                  </IconButton>
                </Box>

                <Typography
                  sx={{
                    color: "black",
                    fontSize: 8,
                    textAlign: "center",
                    marginBottom: 5,
                  }}
                >
                  {Language(mall, language, "version")} 0.2.5
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: 14,
                    fontWeight: 700,
                    textAlign: "center",
                    marginBlock: 8,
                  }}
                >
                  {/* {t("welcome")} */}
                  {Language(mall, language, "welcome")}
                </Typography>

                <Button
                  variant="contained"
                  color="newRed"
                  fullWidth
                  sx={{ paddingY: 1.2, fontWeight: 700, textTransform: "none" }}
                  onClick={() => navigate("/login")}
                >
                  {Language(mall, language, "login")}
                </Button>
              </>
            )}
          </>
        )}
      </Box>
    </Container>
  );
}
