import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Divider,
} from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CreditCard from "../components/CreditCard";
import countryCodes from "../components/countryCodes.json";
import Language from "../languages";
import Services from "../services/Services";

export default function PaymentPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  const uri = localStorage.getItem("mallUri");
  const storedMall = localStorage.getItem("mall");
  const _mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);

  const [token, setToken] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [country, setCountry] = useState("TR");
  const [zipCode, setZipCode] = useState("");
  const [countries, setCountries] = useState([]);
  const [htmlContent, setHtmlContent] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [sessionIDText, setSessionIDText] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentID, setPaymentID] = useState("");

  useEffect(() => {
    setCountries(countryCodes);
    const token = localStorage.getItem("token");
    setToken(token);
    const phone = localStorage.getItem("phone");
    console.log("item: ", item);
    Services.GetCustomer(token, phone)
      .then((res) => {
        if (res.data.status) {
          const customerData = res.data.result;
          setCustomerId(customerData.id);
          setFirstName(customerData.name);
          setLastName(customerData.lastname);
        } else {
          console.log("GetCustomer servisi çalışmadı.");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleExpiryDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");

    if (value.length >= 2) {
      let month = value.slice(0, 2);
      if (parseInt(month, 10) > 12) {
        month = "12";
      }
      value = month + (value.length > 2 ? `/${value.slice(2, 4)}` : "");
    }
    setExpiryDate(value);
  };

  function isValidCardNumber(cardNumber) {
    // Kart numarasını dizeye çevirin ve ters çevirin
    const reversedDigits = cardNumber
      ?.toString()
      .split("")
      .reverse()
      .map(Number);

    // Algoritma gereği rakamları işleyin
    const sum = reversedDigits.reduce((acc, digit, idx) => {
      if (idx % 2 === 1) {
        // Çift indeksli (ters çevrildiğinde tek pozisyon) rakamları ikiyle çarp
        let doubled = digit * 2;
        // Eğer ikiyle çarpma sonucu 9'dan büyükse, rakamları toplayarak küçültün
        if (doubled > 9) doubled -= 9;
        return acc + doubled;
      }
      // Tek indeksli (ters çevrildiğinde çift pozisyon) rakamları olduğu gibi ekle
      return acc + digit;
    }, 0);

    // Toplamın 10'a bölünebilir olup olmadığını kontrol edin
    return sum % 10 === 0;
  }

  const isFutureDate = (date) => {
    const [month, year] = date.split("/").map((num) => parseInt(num, 10));
    const today = new Date();
    const expiryDate = new Date(`20${year}`, month - 1);
    return expiryDate > today;
  };

  const isFormComplete =
    firstName &&
    lastName &&
    isValidCardNumber(cardNumber) &&
    cardNumber.length >= 15 &&
    expiryDate &&
    cvv &&
    isFutureDate(expiryDate);

  const handleSubmit = () => {
    setLoading(true);
    const paymentData = {
      firstName,
      lastName,
      cardNumber,
      expiryDate,
      cvv,
      country,
    };

    const sessionID = Math.floor(Math.random() * 1e9)
      ?.toString()
      .padStart(9, "0");
    setSessionIDText(sessionID);
    const cardName = firstName?.trim() + " " + lastName?.trim();
    let price = item[0]?.deliveryTypeInfo?.price;
    let totalPrice = 0;

    if (JSON.parse(storedMall)[0].calculationUnit === "service") {
      totalPrice = price;
    } else if (JSON.parse(storedMall)[0].calculationUnit === "per-package") {
      totalPrice = price * item.length;
    }

    console.log({
      sessionID: +sessionID,
      customerID: +customerId,
      cardName: cardName,
      cardNumber: cardNumber,
      expiryDate: expiryDate.replace("/", ""),
      cvv: cvv,
      price: totalPrice?.toString(),
    });

    ///////////////////
    // const tempPaymentID = Math.floor(Math.random() * 1e9)
    //   ?.toString()
    //   .padStart(9, "0");
    // const promises = item?.map((item) =>
    //   Services.SetPaid(token, tempPaymentID, "online", item?.postID)
    // );

    // Promise.all(promises)
    //   .then(() => {
    //     navigate("/payment-final", { state: item });
    //   })
    //   .catch((error) => {
    //     console.error("An error occurred:", error);
    //   });

    ///////////////
    Services.Payment(
      +sessionID,
      +customerId,
      cardName,
      cardNumber,
      expiryDate.replace("/", ""),
      cvv,
      totalPrice.toString()
    )
      .then((res) => {
        if (res.data.status) {
          console.log("Response: ", res.data);
          setPaymentID(res.data.paymentId);
          setHtmlContent(res.data.htmlContent);
        } else {
          console.log("ödeme servisi çalışmadı.");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (htmlContent && htmlContent?.length > 0) {
      const interval = setInterval(() => {
        Services.CheckPayment(paymentID)
          .then((res) => {
            console.log("checkPayment: ", res.data);
            if (res.data.paymentStatus === true) {
              // setPaymentID(res.data.iyzicoPaymentId);

              const promises = item?.map((item) =>
                Services.SetPaid(
                  token,
                  res.data.iyzicoPaymentId,
                  "online",
                  item?.postID
                )
              );

              Promise.all(promises)
                .then(() => {
                  navigate("/payment-final", { state: item });
                })
                .catch((error) => {
                  console.error("An error occurred:", error);
                });
              // for (let i = 0; i < item?.length; i++) {
              //   Services.SetPaid(
              //     token,
              //     res.data.iyzicoPaymentId,
              //     "online",
              //     item[i]?.postID
              //   );
              // }
              // navigate("/payment-final", { state: item });
              return () => clearInterval(interval);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [htmlContent]);

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent
          middle="logo"
          goBack={() => navigate(`/${uri}`)}
          left="back"
          right="icon"
        />
        <Box sx={{ marginTop: 2 }}>
          <Box>
            <Typography component="span" sx={{ color: "#1F2A37" }}>
              Easy Point{" "}
            </Typography>
            <Typography
              component="span"
              sx={{ fontWeight: "bolder", color: "#1F2A37" }}
            >
              Hands Free
            </Typography>

            <Divider sx={{ backgroundColor: "#EAEAEA", my: 1 }} />
          </Box>

          {/* Eğer htmlContent varsa iframe ile göster */}
          {htmlContent ? (
            <iframe
              srcDoc={htmlContent}
              style={{
                width: "100vw",
                height: "100vh",
                border: "none",
                position: "fixed",
                top: 20,
                left: 0,
                zIndex: 9999,
              }}
              title="Payment Content"
            />
          ) : (
            <div
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                marginTop: 30,
              }}
            >
              <CreditCard
                cardNumber={cardNumber}
                cardHolder={firstName + " " + lastName}
                expiryDate={expiryDate}
              />
            </div>
          )}

          {!htmlContent && (
            <Box component="form" sx={{ mt: 3 }}>
              <TextField
                fullWidth
                label={Language(_mall, language, "cardHolderName")}
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label={Language(_mall, language, "cardHolderLastname")}
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label={Language(_mall, language, "cardNumber")}
                variant="outlined"
                value={cardNumber}
                inputProps={{ maxLength: 16 }}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ""); // Sadece rakam al
                  setCardNumber(value);
                }}
                sx={{ mb: 2 }}
              />
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                <TextField
                  label={Language(_mall, language, "expiryDate")}
                  variant="outlined"
                  value={expiryDate}
                  onChange={handleExpiryDateChange}
                  inputProps={{ maxLength: 5 }}
                  sx={{ flex: 1, mr: 1 }}
                />
                <TextField
                  label="CVV"
                  variant="outlined"
                  value={cvv}
                  inputProps={{ maxLength: 4 }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ""); // Sadece rakam al
                    setCvv(value);
                  }}
                  sx={{ flex: 1, ml: 1 }}
                />
              </Box>
              {/* <Typography sx={{ mb: 1 }}>
                {Language(_mall, language, "enterZipCode")}
              </Typography> */}
              {/* <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <FormControl sx={{ minWidth: 80, mr: 2 }}>
                  <InputLabel>
                    {Language(_mall, language, "country")}
                  </InputLabel>
                  <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    label={Language(_mall, language, "country")}
                  >
                    {countries.map((item) => (
                      <MenuItem key={item.code} value={item.code}>
                        <img
                          src={item.image}
                          alt={`${item.name} flag`}
                          style={{ width: 20, marginRight: 8 }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label={Language(_mall, language, "zipCode")}
                  variant="outlined"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </Box> */}
              <Button
                disabled={!isFormComplete || loading}
                fullWidth
                variant="contained"
                color="newRed"
                startIcon={<i className="fa fa-credit-card" />}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  Language(_mall, language, "proceedToPayment")
                )}
              </Button>
            </Box>
          )}

          <Typography
            variant="body2"
            textAlign="center"
            sx={{ mt: 4, color: "#888" }}
          >
            Copyright ©Easy Point 2024.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
