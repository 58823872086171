import {
  Container,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Services from "../services/Services";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Language from "../languages";

export default function SelectMallPage() {
  const navigate = useNavigate();
  const _mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const [token_, setToken_] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [malls, setMalls] = useState([]);
  const [mall, setMall] = useState("");

  const handleContinue = () => {
    setShowLoading(true);
    localStorage.setItem("mallUri", mall);
    Services.GetMallToken(token_, mall)
      .then((response) => {
        localStorage.setItem("mall", JSON.stringify(response.data.result.mall));
      })
      .catch(() => {
        // console.log("mall token hata");
      })
      .finally(() => {
        setShowLoading(false);
        navigate(`/${mall}`);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken_(token);
    Services.GetMalls(token)
      .then((res) => {
        setMalls(res.data.result);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent middle="logo" right="icon" isLogin={true}/>

        <Box
          sx={{
            marginTop: 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            component="h4"
            textAlign="center"
            sx={{
              lineHeight: "26.63px",
              fontSize: 22,
              fontWeight: 700,
              marginBottom: 2,
            }}
          >
            {Language(_mall, language, "selectMall")}
          </Typography>

          {!showLoading && (
            <FormControl fullWidth>
              <InputLabel
                style={{ color: "#C1C6C9", fontSize: 15 }}
                id="demo-simple-select-label"
              >
                {Language(_mall, language, "mall")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="AVM"
                name="avm"
                value={mall}
                onChange={(e) => setMall(e.target.value)}
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#ffffff",
                  borderColor: "#C1C6C9",
                }}
              >
                {malls?.map((mall) => (
                  <MenuItem key={mall.id} value={mall.uri}>
                    {mall.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>

        <Button
          variant="contained"
          color="newRed"
          fullWidth
          sx={{ paddingY: 1.2 }}
          onClick={handleContinue}
          disabled={mall === ""}
        >
          {showLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            Language(_mall, language, "continue")
          )}
        </Button>
      </Box>
    </Container>
  );
}
