export default function Language(
  mall = "default",
  activeLanguage = "tr",
  request,
  variant = ""
) {
  const word = {
    default: {
      tr: {
        // header
        createPost: "GÖNDERİ OLUŞTUR",
        // select mall
        mall: "AVM",
        selectMall: "AVM Seçiniz",
        continue: "DEVAM ET",
        // post listing
        welcome: "Handsfree'ye hoşgeldiniz! Lütfen giriş yapın.",
        login: "Giriş Yap",
        createHandsfree: "{variant} Talebi Oluştur",
        handsfreeRequests: "{variant} Talepleri",
        emptyHandsfreeRequests: "{variant} talebiniz bulunmamaktadır.",
        awaitingStore: "Mağazadan onay bekleniyor",
        awaitingYou: "Sizden onay bekleniyor",
        readyPackages: "Teslime Hazır Paketler",
        pickup: "Teslim alınabilir",
        locker: "Dolapta",
        emptyReadyPackages: "Teslime hazır olan paketiniz bulunmamaktadır.",
        onthewayPackges: "Yoldaki Paketler",
        ontheway: "Yolda!",
        courierOnDelivery: "Kurye dağıtımda",
        emptyOnthewayPackages: "Yolda olan paketiniz bulunmamaktadır.",
        storePackages: "Mağazadaki Paketler",
        inthestore: "Mağazada",
        emptyStorePackages: "Mağazada paketiniz bulunmamaktadır.",
        pastRequests: "Geçmiş Talepler",
        completed: "Tamamlandı",
        canceled: "İptal edildi",
        emptyPastRequests: "Geçmiş talebiniz bulunmamaktadır.",
        // last update
        lastUpdate: "Son Güncelleme",
        refresh: "Yenile",
        version: "Versiyon",
        // create request
        selectStoreTitle: "Lütfen hizmet almak istediğiniz mağazayı seçiniz",
        selectPhone: "Lütfen telefon numaranızı girin",
        firm: "Mağaza",
        name: "Ad",
        lastname: "Soyad",
        phone: "Telefon",
        roomNo: "Oda No",
        code: "Kod",
        codeAgain: "Tekrar Kod Gönder",
        conditions1: "{variant} hizmetinden faydalanmak için",
        conditions2: "Kullanım Koşulları",
        conditions3: "ve ücretlendirmeleri kabul ediyorum.",
        taxFree: "Aldığım ürünlerde tax refund özelliğini kullanmak istiyorum.",
        selecthotel: "Otel seçiniz.",
        enterPredefinedAddress: "Ön tanımlı adres seçiniz.",
        deliveryType: "Teslimat Tipi",
        deliverToAddress: "Adrese Teslim",
        deliverToLockBox: "Dolaba Teslim",
        pointType: "Nokta Tipi",
        location: "Lokasyon",
        addressType: "Adres Tipi",
        hotel: "Otel",
        city: "İl",
        district: "İlçe",
        town: "Mahalle",
        enterAddress: "Adres Gir",
        lockboxLocation: "Dolap Lokasyonu",
        enterFullAddress: "Açık adres bilginizi girin...",
        enterAddressNote: "Adres açıklaması ve varsa notunuzu girin..",
        enterNote: "Notunuz varsa girin..",
        priceWillBeCalculation:
          "Ücret {variant} x Paket adedi kadar olacaktır.",
        priceWillBe: "Ücret {variant} olacaktır.",
        serviceIsFree: "Bu hizmet ücretsizdir.",
        timeInformationBefore:
          "16:00'a kadar olan talepleriniz, aynı gün 16:00-18:00 saatleri arasında teslim edilecektir.",
        timeInformationAfter:
          "16:00'dan sonraki talepleriniz, ertesi gün 16:00-18:00 saatleri arasında teslim edilecektir.",
        // confirm packages
        pendingPackages: "Onay Bekleyen Paketler",
        timeLimit:
          "Hands Free gönderilerimi en az 1 saat sonra alacağımı kabul ediyorum.",
        timeLimitLoL:
          "Hands Free gönderilerimi en az 2 saat sonra alacağımı kabul ediyorum.",
        create: "OLUŞTUR",
        beingCreated: "Oluşturuluyor...",
        cancel: "İPTAL",
        canceling: "İptal Ediliyor...",
        confirmSuccessMessage:
          "Paketleri onayladınız, iyi alışverişler dileriz.",
        rejectSuccessMessage:
          "Paketler iptal edilmiştir, iyi alışverişler dileriz.",
        deliveryAddress: "Teslim edileceği adres",
        additionalNote: "Notunuz",
        confirmAndCreate: "Onayla ve Oluştur",
        confirmAndPay: "Onayla ve Ödemeye Git",
        // detail
        request: "Talep",
        package: "Paket",
        deliveryInfo: "Teslimat Bilgisi",
        deliveryStatus: "Teslimat Durumu",
        unknown: "Bilinmiyor",
        approved: "Onaylandı",
        rejected: "Talep reddedildi",
        deliveryCode: "Teslimat Şifresi",
        creationDate: "Oluşturulma Tarihi",
        store: "Mağaza",
        orderNo: "Sipariş No",
        lockerNo: "Dolap No",
        locationCode: "Lokasyon Adı",
        deadlineForDelivery: "Son Teslim Tarihi",
        navigation: "Yol Tarifi",
        cargoTrackingNo: "Kargo Takip Numarası",
        paymentStatus: "Ödeme Durumu",
        paid: "Ödenmiş",
        cash: "Nakit",
        creditCard: "Kredi Kartı",
        unpaid: "Ödenmemiş",
        paymentMethod: "Ödeme Yöntemi",
        online: "Online",
        cashOrPost: "Nakit veya post",
        // modals
        success: "Başarılı",
        successMessage: "Talep başarıyla oluşturulmuştur.",
        error: "Hata",
        errorMessage: "İşlem gerçekleştirilemedi.",
        okay: "Tamam",
        close: "Kapat",
        agree: "Onaylıyorum",
        consent:
          "6563 sayılı kanun kapsamında, Avm Lojistik Tic. A.Ş tarafından tarafıma verilmekte olan hizmetler kapsamında, herhangi bir iletişim aracı kullanarak (SMS, e-posta, telefon, faks, çağrı merkezi, v.b. gibi) tarafıma her türlü bilgilendirme, tanıtım, reklam, ürün teklifleri, promosyonlar, kampanyalar, memnuniyet değerlendirme çalışmaları ve duyuruların iletilmesi amacıyla tarafımla iletişime geçilmesine açık rıza veriyorum.",
        // package summary
        YourPackagesToBeDelivered: "Teslim Edilecek Paketleriniz",
        shopBag: "Mağaza Poşeti",
        infoConfirm: "Vermiş olduğum bilgilerin doğru olduğunu onaylıyorum",
        goToPay: "Ödemeye Git",
        // payment
        cardHolderName: "Kart Sahibinin Adı",
        cardHolderLastname: "Kart Sahibinin Soyadı",
        cardNumber: "Kart Numarası",
        expiryDate: "Son Geçerlilik Tarihi",
        country: "Ülke",
        zipCode: "Zip / Posta Kodu",
        proceedToPayment: "Ödemeye Devam Et",
        cardOwner: "Kart Sahibi",
        enterZipCode: "Lütfen Zip / Posta Kodunuzu Girin.",
        transactionSuccess: "İşleminiz Başarıyla Tamamlandı.",
        packagesToDeliver: "Teslim Edilecek Paketleriniz:",
        storeBags: "{variant} Adet Mağaza Poşeti",
        packageCount: "Paket Adeti",
        packageNumbers: "Paket Numaraları",
        returnToHome: "Ana Sayfaya Dön",
        addNewPackage: "Yeni Paket/Poşet Bırak",
      },
      en: {
        // header
        createPost: "CREATE POST",
        // select mall
        mall: "Shopping Center",
        selectMall: "Select Shopping Center",
        continue: "CONTINUE",
        // post listing
        welcome: "Welcome to Handsfree! Please log in.",
        login: "Login",
        createHandsfree: "Request {variant}",
        handsfreeRequests: "{variant} Requests",
        emptyHandsfreeRequests: "You do not have a {variant} request.",
        awaitingStore: "Awaiting approval from the store",
        awaitingYou: "Awaiting approval from you",
        readyPackages: "Packages ready for pick-up",
        pickup: "Ready for pick-up",
        locker: "In the locker",
        emptyReadyPackages: "You do not have any packages ready for pick-up.",
        onthewayPackges: "Packages on delivery",
        ontheway: "On the Way!",
        courierOnDelivery: "Courier on delivery",
        emptyOnthewayPackages: "You do not have any packages on delivery.",
        storePackages: "Packages In the Store",
        inthestore: "In the Store",
        emptyStorePackages: "You do not have any packages in the store.",
        pastRequests: "Past Requests",
        completed: "Completed",
        canceled: "Canceled",
        emptyPastRequests: "You do not have any past requests.",
        // last update
        lastUpdate: "Latest Update",
        refresh: "Refresh",
        version: "Version",
        // create request
        selectStoreTitle:
          "Please select the store you would like to receive service from",
        selectPhone: "Please enter your telephone number",
        firm: "Store",
        name: "Name",
        lastname: "Lastname",
        phone: "Phone",
        roomNo: "Room No",
        code: "Code",
        codeAgain: "Resend Code",
        conditions1: "I agree to the",
        conditions2: "conditions",
        conditions3: "and charges for the {variant} service.",
        taxFree: "I would like to use tax refund on my purchases.",
        selecthotel: "Select a hotel.",
        enterPredefinedAddress: "Select predefined address.",
        deliveryType: "Delivery Type",
        deliverToAddress: "Deliver To Address",
        deliverToLockBox: "Deliver To Lockbox",
        pointType: "Point Type",
        location: "Location",
        addressType: "Address Type",
        hotel: "Hotel",
        city: "City",
        district: "District",
        town: "Town",
        enterAddress: "Enter Address",
        lockboxLocation: "Lockbox Location",
        enterFullAddress: "Enter your full address...",
        enterAddressNote:
          "Enter address description and any notes if applicable..",
        enterNote: "Enter notes if applicable..",
        priceWillBeCalculation:
          "The fee will be {variant} x number of packages.",
        priceWillBe: "Price will be {variant}.",
        serviceIsFree: "This Service is free.",
        timeInformationBefore:
          "Your requests until 16:00 will be delivered on the same day between 16:00-18:00.",
        timeInformationAfter:
          "Requests after 16:00 will be delivered the next day between 16:00-18:00.",
        // confirm packages
        pendingPackages: "Packages Pending Approval",
        timeLimit:
          "I agree to receive my Hands Free packages at least 1 hour later.",
        timeLimitLoL:
          "I agree to receive my Hands Free packages at least 2 hour later.",
        create: "CREATE",
        beingCreated: "Being Created...",
        cancel: "CANCEL",
        canceling: "Canceling...",
        confirmSuccessMessage:
          "You have confirmed the packages, have a good shopping experience.",
        rejectSuccessMessage:
          "Packages have been canceled, we wish you a good shopping experience.",
        deliveryAddress: "Delivery Address",
        additionalNote: "Additional Note",
        confirmAndCreate: "Confirm and Create",
        confirmAndPay: "Confirm and Pay",
        // detail
        request: "Request",
        package: "Package",
        deliveryInfo: "Delivery Info",
        deliveryStatus: "Delivery Status",
        unknown: "Unknown",
        approved: "Approved",
        rejected: "Rejected declined",
        deliveryCode: "Delivery Code",
        creationDate: "Creation Date",
        store: "Store",
        orderNo: "Order No",
        lockerNo: "Locker No",
        locationCode: "Location Title",
        deadlineForDelivery: "Deadline for Delivery",
        navigation: "Navigation",
        cargoTrackingNo: "Cargo Tracking Number",
        paymentStatus: "Payment Status",
        paid: "Paid",
        cash: "Cash",
        creditCard: "Credit Card",
        unpaid: "Unpaid",
        paymentMethod: "Payment Method",
        online: "Online",
        cashOrPost: "Cash or post",
        // modals
        success: "Success",
        successMessage: "The request was successfully created.",
        error: "Error",
        errorMessage: "Transaction could not be processed.",
        okay: "Okay",
        close: "Close",
        agree: "Approve",
        consent:
          "Within the scope of Law No. 6563, within the scope of the services provided to me by Avm Lojistik Tic. A.Ş., I give my explicit consent to be contacted by any means of communication (such as SMS, e-mail, telephone, fax, call centre, etc.) for the purpose of communicating all kinds of information, promotion, advertisement, product offers, promotions, campaigns, satisfaction evaluation studies and announcements to me.",
        // package summary
        YourPackagesToBeDelivered: "Your Packages to be Delivered",
        shopBag: "Shop Bag",
        infoConfirm:
          "I confirm that the information I have provided is correct",
        goToPay: "Go to Pay",
        //payment
        cardHolderName: "Card Holder's First Name",
        cardHolderLastname: "Card Holder's Last Name",
        cardNumber: "Card Number",
        expiryDate: "Expiry Date",
        country: "Country",
        zipCode: "Zip / Postal Code",
        proceedToPayment: "Proceed to Payment",
        cardOwner: "Card Holder",
        enterZipCode: "Please enter your Zip / Postal Code.",
        transactionSuccess: "Your transaction has been successfully completed.",
        packagesToDeliver: "Your Packages to Deliver:",
        storeBags: "{variant} Store Bag(s)",
        packageCount: "Package Count",
        packageNumbers: "Package Numbers",
        returnToHome: "Return to Home",
        addNewPackage: "Drop New Package/Bag",
      },
    },

    galataport: {
      tr: {
        // header
        createPost: "TALEP OLUŞTUR",
        // select mall
        mall: "AVM",
        selectMall: "AVM Seçiniz",
        continue: "DEVAM ET",
        // post listing
        welcome: "Bags-Free'ye hoşgeldiniz! Lütfen giriş yapın.",
        login: "Giriş Yap",
        createHandsfree: "{variant} Talebi Oluştur",
        handsfreeRequests: "{variant} Talepleri",
        emptyHandsfreeRequests: "{variant} talebiniz bulunmamaktadır.",
        awaitingStore: "Mağazadan onay bekleniyor",
        awaitingYou: "Sizden onay bekleniyor",
        readyPackages: "Teslime Hazır Paketler",
        pickup: "Teslim alınabilir",
        locker: "Dolapta",
        emptyReadyPackages: "Teslime hazır olan paketiniz bulunmamaktadır.",
        onthewayPackges: "Yoldaki Paketler",
        ontheway: "Yolda!",
        courierOnDelivery: "Kurye dağıtımda",
        emptyOnthewayPackages: "Yolda olan paketiniz bulunmamaktadır.",
        storePackages: "Mağazadaki Paketler",
        inthestore: "Mağazada",
        emptyStorePackages: "Mağazada paketiniz bulunmamaktadır.",
        pastRequests: "Geçmiş Talepler",
        completed: "Tamamlandı",
        canceled: "İptal edildi",
        emptyPastRequests: "Geçmiş talebiniz bulunmamaktadır.",
        // last update
        lastUpdate: "Son Güncelleme",
        refresh: "Yenile",
        version: "Versiyon",
        // create request
        selectStoreTitle: "Lütfen hizmet almak istediğiniz mağazayı seçiniz",
        selectPhone: "Lütfen telefon numaranızı girin",
        firm: "Mağaza",
        name: "Ad",
        lastname: "Soyad",
        phone: "Telefon",
        roomNo: "Oda No",
        code: "Kod",
        codeAgain: "Tekrar Kod Gönder",
        conditions1: "{variant} hizmetinden faydalanmak için",
        conditions2: "Kullanım Koşulları",
        conditions3: "ve ücretlendirmeleri kabul ediyorum.",
        taxFree: "Aldığım ürünlerde tax refund özelliğini kullanmak istiyorum.",
        // confirm packages
        pendingPackages: "Onay Bekleyen Paketler",
        timeLimit:
          "Bags-Free gönderilerimi en az 1 saat sonra alacağımı kabul ediyorum.",
        create: "TALEBİNİZİ ONAYLAYIN",
        beingCreated: "Oluşturuluyor...",
        cancel: "İPTAL",
        canceling: "İptal Ediliyor...",
        confirmSuccessMessage:
          "Paketleri onayladınız, iyi alışverişler dileriz.",
        rejectSuccessMessage:
          "Paketler iptal edilmiştir, iyi alışverişler dileriz.",
        selecthotel: "Otel seçiniz.",
        enterPredefinedAddress: "Ön tanımlı adres seçiniz.",
        deliveryType: "Teslimat Tipi",
        deliverToAddress: "Adrese Teslim",
        deliverToLockBox: "Dolaba Teslim",
        pointType: "Nokta Tipi",
        location: "Lokasyon",
        addressType: "Adres Tipi",
        hotel: "Otel",
        city: "İl",
        district: "İlçe",
        town: "Mahalle",
        enterAddress: "Adres Gir",
        lockboxLocation: "Dolap Lokasyonu",
        enterFullAddress: "Açık adres bilginizi girin...",
        enterAddressNote: "Adres açıklaması ve varsa notunuzu girin..",
        enterNote: "Notunuz varsa girin..",
        priceWillBeCalculation:
          "Ücret {variant} x Paket adedi kadar olacaktır.",
        priceWillBe: "Ücret {variant} olacaktır.",
        serviceIsFree: "Bu hizmet ücretsizdir.",
        timeInformationBefore:
          "16:00'a kadar olan talepleriniz, aynı gün 16:00-18:00 saatleri arasında teslim edilecektir.",
        timeInformationAfter:
          "16:00'dan sonraki talepleriniz, ertesi gün 16:00-18:00 saatleri arasında teslim edilecektir.",
        deliveryAddress: "Teslim edileceği adres",
        additionalNote: "Notunuz",
        confirmAndCreate: "Onayla ve Oluştur",
        confirmAndPay: "Onayla ve Ödemeye Git",
        // detail
        request: "Talep",
        package: "Paket",
        deliveryInfo: "Teslimat Bilgisi",
        deliveryStatus: "Teslimat Durumu",
        unknown: "Bilinmiyor",
        approved: "Onaylandı",
        rejected: "Talep reddedildi",
        deliveryCode: "Teslimat Şifresi",
        creationDate: "Oluşturulma Tarihi",
        store: "Mağaza",
        orderNo: "Sipariş No",
        lockerNo: "Dolap No",
        locationCode: "Lokasyon Adı",
        deadlineForDelivery: "Son Teslim Tarihi",
        navigation: "Yol Tarifi",
        cargoTrackingNo: "Kargo Takip Numarası",
        paymentStatus: "Ödeme Durumu",
        paid: "Ödenmiş",
        cash: "Nakit",
        creditCard: "Kredi Kartı",
        unpaid: "Ödenmemiş",
        paymentMethod: "Ödeme Yöntemi",
        online: "Online",
        cashOrPost: "Nakit veya post",
        // modals
        success: "Başarılı",
        successMessage: "Talep başarıyla oluşturulmuştur.",
        error: "Hata",
        errorMessage: "İşlem gerçekleştirilemedi.",
        okay: "Tamam",
        close: "Kapat",
        agree: "Onaylıyorum",
        consent:
          "6563 sayılı kanun kapsamında, Avm Lojistik Tic. A.Ş tarafından tarafıma verilmekte olan hizmetler kapsamında, herhangi bir iletişim aracı kullanarak (SMS, e-posta, telefon, faks, çağrı merkezi, v.b. gibi) tarafıma her türlü bilgilendirme ve duyuruların iletilmesi amacıyla tarafımla iletişime geçilmesine açık rıza veriyorum.",
        // package summary
        YourPackagesToBeDelivered: "Teslim Edilecek Paketleriniz",
        shopBag: "Mağaza Poşeti",
        infoConfirm: "Vermiş olduğum bilgilerin doğru olduğunu onaylıyorum",
        goToPay: "Ödemeye Git",
        // payment
        cardHolderName: "Kart Sahibinin Adı",
        cardHolderLastname: "Kart Sahibinin Soyadı",
        cardNumber: "Kart Numarası",
        expiryDate: "Son Geçerlilik Tarihi",
        country: "Ülke",
        zipCode: "Zip / Posta Kodu",
        proceedToPayment: "Ödemeye Devam Et",
        cardOwner: "Kart Sahibi",
        enterZipCode: "Lütfen Zip / Posta Kodunuzu Girin.",
        transactionSuccess: "İşleminiz Başarıyla Tamamlandı.",
        packagesToDeliver: "Teslim Edilecek Paketleriniz:",
        storeBags: "{variant} Adet Mağaza Poşeti",
        packageCount: "Paket Adeti",
        packageNumbers: "Paket Numaraları",
        returnToHome: "Ana Sayfaya Dön",
        addNewPackage: "Yeni Paket/Poşet Bırak",
      },
      en: {
        // header
        createPost: "CREATE REQUEST",
        // select mall
        mall: "Shopping Center",
        selectMall: "Select Shopping Center",
        continue: "CONTINUE",
        // post listing
        welcome: "Welcome to Bags-Free! Please log in.",
        login: "Login",
        createHandsfree: "Request {variant}",
        handsfreeRequests: "{variant} Requests",
        emptyHandsfreeRequests: "You do not have a {variant} request.",
        awaitingStore: "Awaiting approval from the store",
        awaitingYou: "Awaiting approval from you",
        readyPackages: "Packages ready for pick-up",
        pickup: "Ready for pick-up",
        locker: "In the locker",
        emptyReadyPackages: "You do not have any packages ready for pick-up.",
        onthewayPackges: "Packages on delivery",
        ontheway: "On the Way!",
        courierOnDelivery: "Courier on delivery",
        emptyOnthewayPackages: "You do not have any packages on delivery.",
        storePackages: "Packages In the Store",
        inthestore: "In the Store",
        emptyStorePackages: "You do not have any packages in the store.",
        pastRequests: "Past Requests",
        completed: "Completed",
        canceled: "Canceled",
        emptyPastRequests: "You do not have any past requests.",
        // last update
        lastUpdate: "Latest Update",
        refresh: "Refresh",
        version: "Version",
        // create request
        selectStoreTitle:
          "Please select the store you would like to receive service from",
        selectPhone: "Please enter your telephone number",
        firm: "Store",
        name: "Name",
        lastname: "Lastname",
        phone: "Phone",
        roomNo: "Room No",
        code: "Code",
        codeAgain: "Resend Code",
        conditions1: "I agree to the",
        conditions2: "conditions",
        conditions3: "and charges for the {variant} service.",
        taxFree: "I would like to use tax refund on my purchases.",
        selecthotel: "Select a hotel.",
        enterPredefinedAddress: "Select predefined address.",
        deliveryType: "Delivery Type",
        deliverToAddress: "Deliver To Address",
        deliverToLockBox: "Deliver To Lockbox",
        pointType: "Point Type",
        location: "Location",
        addressType: "Address Type",
        hotel: "Hotel",
        city: "City",
        district: "District",
        town: "Town",
        enterAddress: "Enter Address",
        lockboxLocation: "Lockbox Location",
        enterFullAddress: "Enter your full address...",
        enterAddressNote:
          "Enter address description and any notes if applicable..",
        enterNote: "Enter notes if applicable..",
        priceWillBeCalculation:
          "The fee will be {variant} x number of packages.",
        priceWillBe: "Price will be {variant}.",
        serviceIsFree: "This Service is free.",
        timeInformationBefore:
          "Your requests until 16:00 will be delivered on the same day between 16:00-18:00.",
        timeInformationAfter:
          "Requests after 16:00 will be delivered the next day between 16:00-18:00.",
        // confirm packages
        pendingPackages: "Packages Pending Approval",
        timeLimit:
          "I agree to receive my Bags-Free packages at least 1 hour later.",
        create: "APPROVE",
        beingCreated: "Being Created...",
        cancel: "CANCEL",
        canceling: "Canceling...",
        confirmSuccessMessage:
          "You have confirmed the packages, have a good shopping experience.",
        rejectSuccessMessage:
          "Packages have been canceled, we wish you a good shopping experience.",
        deliveryAddress: "Delivery Address",
        additionalNote: "Additional Note",
        confirmAndCreate: "Confirm and Create",
        confirmAndPay: "Confirm and Pay",
        // detail
        request: "Request",
        package: "Package",
        deliveryInfo: "Delivery Info",
        deliveryStatus: "Delivery Status",
        unknown: "Unknown",
        approved: "Approved",
        rejected: "Rejected declined",
        deliveryCode: "Delivery Code",
        creationDate: "Creation Date",
        store: "Store",
        orderNo: "Order No",
        lockerNo: "Locker No",
        locationCode: "Location Title",
        deadlineForDelivery: "Deadline for Delivery",
        navigation: "Navigation",
        cargoTrackingNo: "Cargo Tracking Number",
        paymentStatus: "Payment Status",
        paid: "Paid",
        cash: "Cash",
        creditCard: "Credit Card",
        unpaid: "Unpaid",
        paymentMethod: "Payment Method",
        online: "Online",
        cashOrPost: "Cash or post",
        // modals
        success: "Success",
        successMessage: "The request was successfully created.",
        error: "Error",
        errorMessage: "Transaction could not be processed.",
        okay: "Okay",
        close: "Close",
        agree: "Approve",
        consent:
          "Within the scope of Law No. 6563, within the scope of the services provided to me by Avm Lojistik Tic. A.Ş., I give my explicit consent to be contacted by any means of communication (such as SMS, e-mail, telephone, fax, call centre, etc.) for the purpose of communicating all kinds of information and announcements to me.",
        // package summary
        YourPackagesToBeDelivered: "Your Packages to be Delivered",
        shopBag: "Shop Bag",
        infoConfirm:
          "I confirm that the information I have provided is correct",
        goToPay: "Go to Pay",
        //payment
        cardHolderName: "Card Holder's First Name",
        cardHolderLastname: "Card Holder's Last Name",
        cardNumber: "Card Number",
        expiryDate: "Expiry Date",
        country: "Country",
        zipCode: "Zip / Postal Code",
        proceedToPayment: "Proceed to Payment",
        cardOwner: "Card Holder",
        enterZipCode: "Please enter your Zip / Postal Code.",
        transactionSuccess: "Your transaction has been successfully completed.",
        packagesToDeliver: "Your Packages to Deliver:",
        storeBags: "{variant} Store Bag(s)",
        packageCount: "Package Count",
        packageNumbers: "Package Numbers",
        returnToHome: "Return to Home",
        addNewPackage: "Drop New Package/Bag",
      },
    },
  };

  return typeof word[mall] == "undefined"
    ? word["default"][activeLanguage][request]?.replace("{variant}", variant)
    : word[mall][activeLanguage][request]?.replace("{variant}", variant);
}
