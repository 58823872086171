import {
  Box,
  Container,
  Divider,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate, useLocation } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import bagIcon from "../assets/images/bagIcon.png";
import Language from "../languages";
import { useSelector } from "react-redux";
import Services from "../services/Services";

export default function PaymentFinal() {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  const token = localStorage.getItem("token");
  const uri = localStorage.getItem("mallUri");
  const _mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);

  useEffect(() => {
    console.log(
      "token: ",
      token,
      "item[0]?.customerID: ",
      item[0]?.customerID,
      "item[0]?.orderID: ",
      item[0]?.orderID
    );
    Services.CustomerOrderConfirm(token, item[0]?.customerID, item[0]?.orderID)
      .then((res) => {
        console.log("Last Response: ", res.data);
        if (res.data.status) {
          // setSuccess(true);
        } else {
          // setFailed(true);
        }
      })
      .catch((err) => {
        //   setFailed(true);
        //
      });
  }, []);

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent
          middle="logo"
          goBack={() => navigate(`/${uri}`)}
          left="back"
          right="icon"
        />
        <Box sx={{ marginTop: 2, textAlign: "center" }}>
          <Box sx={{ flexDirection: "row", display: "flex" }}>
            <Typography component="span" sx={{ color: "#1F2A37" }}>
              Easy Point{" "}
            </Typography>
            <Typography
              component="span"
              sx={{ fontWeight: "bolder", color: "#1F2A37", marginLeft: "7px" }}
            >
              Hands Free
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: "#EAEAEA", my: 1 }} />

          <CheckCircleIcon
            sx={{ fontSize: 60, color: "#209F63", marginTop: 10 }}
          />

          <Typography
            variant="h6"
            sx={{
              color: "#1F2A37",
              mt: 2,
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {Language(_mall, language, "transactionSuccess")}
          </Typography>
          <Divider sx={{ backgroundColor: "#EAEAEA", mt: 10 }} />
          <Box sx={{ mt: 4, textAlign: "left" }}>
            <Typography
              component="span"
              sx={{ color: "#6B7280", fontSize: "12px", fontWeight: "600" }}
            >
              {Language(_mall, language, "packagesToDeliver")}{" "}
              <IconButton size="small">
                {/* info icon or any other */}
                <div
                  style={{
                    backgroundColor: "#9CA3AF",
                    width: "12px",
                    height: "12px",
                    borderRadius: 50,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <span style={{ fontSize: 9, color: "#fff" }}>ℹ️</span>
                </div>
              </IconButton>
            </Typography>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    backgroundColor: "#F2F4F7",
                    borderRadius: 2,
                    p: 1,
                    mr: 2,
                  }}
                >
                  <img src={bagIcon} alt="Bag" style={{ height: "47px" }} />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#1F2A37",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {Language(_mall, language, "storeBags", item?.length)}
                  </Typography>
                  <Typography sx={{ color: "#666666", fontSize: "11px" }}>
                    {item[0]?.branchTitle}
                  </Typography>
                  {/* <Typography
                    sx={{
                      color: "#9CA3AF",
                      fontSize: "11px",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    {Language(_mall, language, "packageNumbers")}:
                    <span style={{ color: "#666666" }}>
                      #69769, #48798, #24988
                    </span>
                  </Typography> */}
                </Box>
              </Box>
              <Box>
                <div
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderRadius: 16,
                    width: "96px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    height: "30px",
                  }}
                >
                  <span
                    style={{
                      color: "#374151",
                      fontSize: "10px",
                      fontWeight: "400",
                    }}
                  >
                    {Language(_mall, language, "packageCount")}: {item?.length}
                  </span>
                </div>
              </Box>
            </Box>
          </Box>

          {/* Action Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 8,
              mb: 8,
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#209F63",
                flex: 1,
                borderRadius: 50,
                fontSize: "12px",
              }}
              onClick={() => navigate(`/${uri}`)}
            >
              {Language(_mall, language, "returnToHome")}
            </Button>
            {/* <Button
              variant="contained"
              sx={{
                backgroundColor: "#EF4444",
                flex: 1,
                borderRadius: 50,
                fontSize: "12px",
              }}
            >
              {Language(_mall, language, "addNewPackage")}
            </Button> */}
          </Box>

          {/* Copyright */}
          <Typography variant="body2" textAlign="center" sx={{ color: "#888" }}>
            Copyright ©Easy Point 2024.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
